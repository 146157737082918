import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { conditionsToNodeSelector } from 'store/selectors'
import {
  Dialog, DialogActions, Button, DialogContent, Typography,
} from '@material-ui/core'

const formatValue = (v) => {
  if (typeof v === 'string') {
    return v
  }
  return v ? 'Ja' : 'Nein'
}
const TransparencyTool = ({ onClose }) => {
  const conditionsToNode = useSelector(conditionsToNodeSelector)
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogContent>
        {Object.keys(conditionsToNode).length
          ? (
            <ul style={{ listStyle: 'none' }}>
              {Object.keys(conditionsToNode).map((c) => (
                <li key={c}>
                  <Typography component="h6" variant="subtitle2" display="inline">
                    {c}
                  </Typography>
                  <ul style={{ listStyle: 'disc' }}>
                    {conditionsToNode[c].map((v) => <li>{formatValue(v)}</li>)}
                  </ul>
                </li>
              ))}
            </ul>
          )
          : (
            <Typography variant="subtitle2" display="inline">
              Keine Bedingungen
            </Typography>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Schliesen

        </Button>
      </DialogActions>
    </Dialog>
  )
}

TransparencyTool.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default TransparencyTool
