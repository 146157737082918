const initialState = {
  open: false,
  title: 'general.empty',
  body: 'general.empty',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'OPEN_CONFIRMATION_MODAL':
      return {
        ...state,
        open: true,
        title: action.payload.title,
        body: action.payload.body,
      }
    case 'CLOSE_CONFIRMATION_MODAL':
      return initialState

    default:
      return state
  }
}
