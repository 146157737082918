import { makeStyles } from '@material-ui/core/styles'
import useWindowSize from 'hooks/useWindowSize'


const useStyles = () => {
  const { isMobile } = useWindowSize()

  const styles = makeStyles(() => ({
    logo: {
      width: isMobile ? '70%' : '100%',
    },
  }))
  return styles()
}
export default useStyles
