import React from 'react'
import PropTypes from 'prop-types'
import {
  Select as MuiSelect, FormControl, InputLabel, MenuItem, Checkbox, Chip, ListItemText,
} from '@material-ui/core'

const MultiSelect = ({
  options, value, label, onChange, name, error,
}) => (
  <FormControl
    variant="outlined"
    fullWidth
    margin="normal"
    error={error}
  >
    <InputLabel id={`label-for-${name}`}>{label}</InputLabel>
    <MuiSelect
      labelId={`label-for-${name}`}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
      multiple
      renderValue={(selected) => (
        <div>
          {selected.map((v) => (
            <Chip key={v} label={options.find((o) => o.value === v).label} />
          ))}
        </div>
      )}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox checked={value.indexOf(option.value) > -1} />
          <ListItemText primary={option.label} />
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)

MultiSelect.defaultProps = {
  error: '',
  options: [],
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape(
      { label: PropTypes.string.isRequired, value: PropTypes.string.isRequired },
    ),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default MultiSelect
