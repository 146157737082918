
import { makeStyles } from '@material-ui/core/styles'

const useStyles = (open) => {
  const styles = makeStyles(() => ({
    container: {
      height: '100%',
    },
    graphContainer: {
      padding: '.5rem 1rem',
      height: '100%',
    },
    legends: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    legendIcon: {
      padding: '5px',
    },
    graph: {
      height: !open
        ? 'calc(100% - 65px)'
        : '50%',
    },
    rightSideNavContainer: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      height: '100%',
      overflowY: 'auto',
    },
    leftSideNavContainer: {
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      height: '100%',
      overflowY: 'auto',
    },
    toolbar: {
      display: 'flex',
    },
    print: {
      width: '100%',
      height: '100%',
      display: 'flex',
    },
    printGraph: {
      '& .fas': {
        padding: '15px',
      },
    },
  }))

  return styles()
}

export default useStyles
