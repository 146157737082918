import React from 'react'
import { Grid, Link, Typography } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { hooks } from 'lq-ui'
import PropTypes from 'prop-types'
import {
  jobcenter, kf, kreisminden, arbeitAg, bamf, arbeitAgMobile, kfMobile, kreismindenMobile, kim
} from 'assets/images'
import useWindowSize from 'hooks/useWindowSize'
import useStyles from './styles'


const Footer = ({ showBg }) => {
  const { isMobile } = useWindowSize()

  const classes = useStyles(isMobile)

  const { i18n: { useMessage } } = hooks


  return (
    <footer className={classes.root}>
      {showBg && <div className={classes.bg} />}
      <Grid container justify="space-between" alignItems="center" className={classes.container}>

        <Grid item className={classes.logoItem}>
          <img src={kim} alt="KIM" className={classes.image} />
        </Grid>
        <Grid item className={classes.logoItem}>
          <img src={isMobile ? kreismindenMobile : kreisminden} alt="Kreis Minden" className={classes.image} />
        </Grid>
        <Grid item className={classes.logoItem}>
          <img src={jobcenter} alt="Jobcenter" className={classes.image} />
        </Grid>
        <Grid item className={classes.logoItem}>
          <img src={isMobile ? kfMobile : kf} alt="Ministerium für Kinder Familien" className={classes.image} />
        </Grid>
        <Grid item className={classes.logoItem}>
          <img src={isMobile ? arbeitAgMobile : arbeitAg} alt="Arbeits Agentur" className={classes.image} />
        </Grid>
        <Grid item className={classes.logoItem}>
          <img src={bamf} alt="bamf" className={classes.image} />
        </Grid>
        <Grid item>
          <Grid container>
            <Link component={RouterLink} to="/editor">
              <Typography variant="subtitle1" className={classes.link}>
                Admin
                {/* {useMessage('landing.imprint')} */}
              </Typography>
            </Link>

            <Typography variant="subtitle1" className={classes.link}>
              |
              {/* {useMessage('landing.imprint')} */}
            </Typography>
            <Link component={RouterLink} to="/impressum">
              <Typography variant="subtitle1" className={classes.link}>
                {useMessage('landing.imprint')}
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Grid>


    </footer>
  )
}

Footer.propTypes = {
  showBg: PropTypes.bool,
}

Footer.defaultProps = {
  showBg: false,
}

export default Footer
