const filterNodeByExclusionCriteria = (node, conditions) => {
  if (!conditions) {
    return true
  }
  // console.log(conditions)

  // return !Object.entries(conditions).every(([key, value]) => node.exclusionCriteria[key]
  // && node.exclusionCriteria[key].includes(value))

  const shouldExclude = (criteria) => Object.entries(criteria).every(([key, value]) => value.includes(conditions[key]))


  const excludeNode = node?.exclusionCriteria.length
    ? node.exclusionCriteria.some(shouldExclude)
    : false


  return !excludeNode
}

export { filterNodeByExclusionCriteria }
