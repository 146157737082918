import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { hooks } from 'lq-ui'
import {
  Button,
  Dialog, DialogTitle, DialogContent,
  DialogActions,
} from '@material-ui/core'


const Confirmation = () => {
  const { open, title, body } = useSelector((state) => state.confirmationModal)
  const dispatch = useDispatch()
  const handleClose = () => dispatch({ type: 'CLOSE_CONFIRMATION_MODAL' })
  const { i18n: { useMessage } } = hooks
  const dialogTitle = useMessage(title)
  const dialogBody = useMessage(body)

  React.useEffect(() => {
    if (open) {
      setTimeout(() => dispatch({ type: 'CLOSE_CONFIRMATION_MODAL' }), 3000)
    }
  }, [dispatch, open])

  return (
    <>
      {
      open
      && (
      <Dialog open onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {dialogBody}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="secondary"
            variant="contained"
          >
            Schließen
          </Button>
        </DialogActions>
      </Dialog>
      )
    }
    </>
  )
}

export default Confirmation
