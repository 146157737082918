import React from 'react'
import PropTypes from 'prop-types'
import { hooks } from 'lq-ui'
import ProviderMap from 'components/ProviderMap'
import useAddress from 'components/Meetings/MeetingCard/useAddress'
import { Typography, Grid, Link } from '@material-ui/core'

const RenderMeetingViewElement = ({ label, meeting }) => {
  const address = useAddress(meeting.address, meeting.map)

  const renderValue = () => {
    switch (label) {
      case 'address':
        return <>{address.value}</>
      case 'email':
        return <Link href={`mailto:${meeting[label]}`}>{meeting[label]}</Link>
      case 'phone':
        return <Link href={`tel:${meeting[label]}`}>{meeting[label]}</Link>
      default:
        return <>{meeting[label]}</>
    }
  }

  return (
    <>
      <Grid container justify="space-between" style={{ wordBreak: 'break-word' }}>
        <Typography variant="subtitle2" component="b">
          {hooks.i18n.useMessage(`meeting.card.${label}`)}
          :
          {' '}
        </Typography>
        <Typography variant="body2" component="p">
          {renderValue(meeting[label])}
        </Typography>
      </Grid>
      {label === 'address' && (
      <div style={{ margin: '8px 0' }}>
        {' '}
        <ProviderMap address={address} editing={false} />
      </div>
      )}
    </>
  )
}

RenderMeetingViewElement.propTypes = {
  meeting: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    serviceHours: PropTypes.string,
    contactName: PropTypes.string,
    shortDescription: PropTypes.string,
    provider: PropTypes.string,
    district: PropTypes.string,
    address: PropTypes.string,
    map: PropTypes.string,
  }).isRequired,
  label: PropTypes.string.isRequired,
}

export default RenderMeetingViewElement
