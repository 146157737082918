import React from 'react'
import PropTypes from 'prop-types'
import Radio from 'components/GraphEditorComponents/Radio'

const BoolConditionEditor = ({ label, value, handleChangeCondition }) => {
  const onChange = (e) => handleChangeCondition(e === 'true')
  const items = [
    { value: true, label: 'Ja' },
    { value: false, label: 'Nein' },
  ]
  return (
    <Radio
      value={value}
      label={label}
      onChange={onChange}
      items={items}
      color="#3A6311"
    />
  )
}

BoolConditionEditor.defaultProps = {
  label: null,
  handleChangeCondition: null,
  value: null,
}

BoolConditionEditor.propTypes = {
  label: PropTypes.string,
  handleChangeCondition: PropTypes.func,
  value: PropTypes.bool,
}

export default BoolConditionEditor
