import React from 'react'
import { Heading } from 'lq-ui'
import privacyStatement from 'messages/privacyStatement'
import imprint from 'messages/imprint'
import styles from './ImpressumStyles'

const Impressum = () => {
  const classes = styles()
  return (
    <div className={classes.impressumContent}>
      <Heading level="h3">
        {imprint['header.0']}
      </Heading>
      <Heading level="h4">
        {imprint['header.1']}
      </Heading>
      <br />
      {imprint['paragraph.1']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.2']}
      </Heading>
      <br />
      {imprint['paragraph.2']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.3']}
      </Heading>
      <br />
      {imprint['paragraph.3']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.4']}
      </Heading>
      <br />
      {imprint['paragraph.4']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.5']}
      </Heading>
      <br />
      {imprint['paragraph.5']}
      <br />
      <br />
      <Heading level="h4">
        {imprint['header.6']}
      </Heading>
      <br />
      {imprint['paragraph.6']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.7']}
      </Heading>
      <br />
      {imprint['paragraph.7']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.8']}
      </Heading>
      <br />
      {imprint['paragraph.8']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.9']}
      </Heading>
      <br />
      {imprint['paragraph.9']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.10']}
      </Heading>
      <br />
      {imprint['paragraph.10']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.11']}
      </Heading>
      <br />
      {imprint['paragraph.11']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.12']}
      </Heading>
      <br />
      {imprint['paragraph.12']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.13']}
      </Heading>
      <br />
      {imprint['paragraph.13']}
      <br />
      <br />
      <Heading level="h6">
        {imprint['header.14']}
      </Heading>
      <br />
      {imprint['paragraph.14']}
      <br />
      <br />
      <Heading level="h3">
        {privacyStatement['header.0']}
      </Heading>
      <br />
      <Heading level="h4">
        {privacyStatement['header.1']}
      </Heading>
      <br />
      {privacyStatement['paragraph.1']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.2']}
      </Heading>
      <br />
      {privacyStatement['paragraph.2']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.3']}
      </Heading>
      <br />
      {privacyStatement['paragraph.3']}
      <br />
      <br />
      <Heading level="h5">
        {privacyStatement['header.4']}
      </Heading>
      {privacyStatement['paragraph.4']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.5']}
      </Heading>
      <br />
      {privacyStatement['paragraph.5']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.6']}
      </Heading>
      <br />
      {privacyStatement['paragraph.6']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.7']}
      </Heading>
      <br />
      {privacyStatement['paragraph.7']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.8']}
      </Heading>
      <br />
      {privacyStatement['paragraph.8']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.9']}
      </Heading>
      <br />
      {privacyStatement['paragraph.9']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.10']}
      </Heading>
      <br />
      {privacyStatement['paragraph.10']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.11']}
      </Heading>
      <br />
      {privacyStatement['paragraph.11']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.12']}
      </Heading>
      <br />
      {privacyStatement['paragraph.12']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.13']}
      </Heading>
      <br />
      {privacyStatement['paragraph.13']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.14']}
      </Heading>
      <br />
      {privacyStatement['paragraph.14']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.15']}
      </Heading>
      <br />
      {privacyStatement['paragraph.15']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.16']}
      </Heading>
      <br />
      {privacyStatement['paragraph.16']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.17']}
      </Heading>
      <br />
      {privacyStatement['paragraph.17']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.18']}
      </Heading>
      <br />
      {privacyStatement['paragraph.18']}
      <br />
      <br />
      <Heading level="h6">
        {privacyStatement['header.19']}
      </Heading>
      <br />
      {privacyStatement['paragraph.19']}
      <br />
      <br />
    </div>
  )
}

export default Impressum
