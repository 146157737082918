import api from 'api'
import { upload } from 'api/fileUpload'

export const deleteFile = (id) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const graph = await api.graph.set({
      nodes,
      edges,
      providers,
      meetings,
      files: files.filter((file) => file.id !== id),
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const updateFile = ({ id, title, subtitle }) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const graph = await api.graph.set({
      nodes,
      edges,
      providers,
      meetings,
      files: files.map((file) => (file.id === id ? ({ ...file, title, subtitle }) : file)),
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const uploadFile = (data) => async (dispatch) => {
  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const graph = await upload('/upload', data)
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}
