import React from 'react'
import useStyles from './styles'

const HomeLogo = () => {
  const classes = useStyles()
  return (
    <img src="assets/intool-logo.png" alt="logo" className={classes.logo} />
  )
}

export default HomeLogo
