import { useJss } from 'lq-ui'

export default (fixedWidth, print) => {
  const useStyles = useJss({
    root: {
      marginTop: '12px',
      marginBottom: '12px',
    },
    card: {
      whiteSpace: 'pre-line',
      width: fixedWidth ? '20rem' : 'inherit',
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: '3rem',
      minHeight: print ? 'auto' : '',
    },
    media: {
      verticalAlign: 'bottom',
      width: '100%',
      height: '20rem',
    },
    labels: {
      display: 'inline',
    },
    shortDescription: {
      paddingTop: 0,
    },
  })

  const classes = useStyles()
  return classes
}
