import React from 'react'
import { AutocompleteChip } from 'components/FormFields'
import PropTypes from 'prop-types'

const Coditions = ({
  conditions, selectedConditions, onChange, onDelete,
}) => (
  <div>
    <AutocompleteChip
      label="Neue Voraussetzung"
      options={conditions.map((c) => c.label)}
      multiple
      value={selectedConditions}
      onChange={onChange}
      onDelete={onDelete}
    />
  </div>
)

Coditions.propTypes = {
  conditions: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedConditions: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default Coditions
