import React from 'react'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import styles from './RadioStyles'

const RadioAdapter = ({
  value, onChange, items, label,
}) => {
  const classes = styles()
  return (
    <FormControl component="fieldset" variant="outlined">
      <FormLabel component="legend" className={`${classes.font} ${classes.label}`}>{label}</FormLabel>
      <RadioGroup
        value={value}
        className={classes.direction}
        onChange={(event) => onChange(event.target.value)}
      >
        {
          items.map(
            (item) => (
              <FormControlLabel
                key={item.label}
                value={item.value}
                className={classes.label}
                control={<Radio classes={{ root: classes.root }} />}
                label={item.label}
              />
            ),
          )
        }
      </RadioGroup>
    </FormControl>
  )
}

RadioAdapter.defaultProps = {
  onChange: null,
  label: null,
  value: null,
}

RadioAdapter.propTypes = {
  onChange: PropTypes.func,
  items: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })).isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
}

export default RadioAdapter
