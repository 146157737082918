import React from 'react'
import Documents from 'components/Documents'
import Layout from '../layouts/General'

const DocumentsPage = () => (
  <Layout title="Info Box">
    <Documents />
  </Layout>
)

export default DocumentsPage
