import React from 'react'
import { hooks } from 'lq-ui'
// import Meetings from 'components/Legacy/Meetings'
import Meetings from 'components/Meetings'
import Layout from '../layouts/General'

const MeetingsPage = () => (
  <Layout title={hooks.i18n.useMessage('meetings.toptitle')}>
    <Meetings />
  </Layout>
)

export default MeetingsPage
