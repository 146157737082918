import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardActions, CardContent, Typography, CardActionArea, IconButton, Grid, Button,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import EditIcon from '@material-ui/icons/Edit'
import { formatLocaleDate } from 'helpers/dateFormat'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DeleteIcon from '@material-ui/icons/Delete'
import { deleteFile } from 'store/actions'
import { useDispatch } from 'react-redux'
import styles from './styles'

const DownloadCard = ({ file, onSelect }) => {
  const { user } = useAuth()
  const dispatch = useDispatch()
  const [open, setOpen] = React.useState(false)
  const onDelete = () => dispatch(deleteFile(file.id)).then(() => setOpen(false))
  const classes = styles()
  return (
    <Card>
      <CardActionArea onClick={() => { window.open(file.link, 'Download') }}>
        <CardContent>
          <Grid justify="space-between" container alignContent="center">
            <Typography gutterBottom variant="h5" component="h2" color="primary" display="inline">
              {file.title}
            </Typography>
            <CloudDownloadIcon color="secondary" />
          </Grid>

          <Typography variant="body2" color="textSecondary" component="p" gutterBottom>
            {file.subtitle}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p" align="right">
            {`hochgeladen am ${formatLocaleDate(file.date)}`}
          </Typography>
        </CardContent>
      </CardActionArea>
      {user
      && (
      <CardActions>
        <IconButton onClick={() => onSelect(file)}>
          <EditIcon color="primary" />
        </IconButton>
        <IconButton aria-label="share" onClick={() => setOpen(true)}>
          <DeleteIcon color="error" />
        </IconButton>
      </CardActions>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Dokument  löschen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sie können jederzeit erneut Dokumente hochladen. Wollen Sie sicher das Dokument endgültig löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDelete()} color="error" variant="contained" className={classes.deleteBtn}>
            Löschen
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

DownloadCard.propTypes = {
  file: PropTypes.objectOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default DownloadCard
