import React from 'react'
// import TopNavigation from 'components/TopNavigation'
import Impressum from 'components/Impressum'
// import { hooks } from 'lq-ui'
import Layout from '../layouts/General'

const ImpressumPage = () => (
  <Layout>
    <Impressum />
  </Layout>
)

export default ImpressumPage
