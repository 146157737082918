import React from 'react'
import { useSelector } from 'react-redux'
import ConditionsGroup from 'components/GraphView/ConditionsGroup'
import { getAllConditions, computeNextCondition } from 'store/selectors'


const NodeConditions = () => {
  const { persona } = useSelector((state) => state.appState)
  const allConditions = useSelector(getAllConditions)
  const nextCondition = useSelector(computeNextCondition)

  const personaCombinedConditions = [...Object.keys(persona), nextCondition, ...allConditions]
    .reduce((a, c) => (a.includes(c) ? a : [...a, c]), [])

  return (
    <ConditionsGroup conditionsLabels={personaCombinedConditions} />
  )
}

export default NodeConditions
