import React from 'react'
import { Grid } from '@material-ui/core'
import { hooks } from 'lq-ui'
import routes from 'pages/routes.json'
import Layout from 'pages/layouts/LandingPage'
import Jumbotron from 'components/LandingPageComponents/Jumbotron'
import BubbleGrid from 'components/LandingPageComponents/BubbleGrid'
import HomeLogo from 'components/LandingPageComponents/Logo'
import useWindowSize from 'hooks/useWindowSize'
import YoutubeEmbed from 'components/YoutubeEmbed'

const LandingPage = () => {
  const { isMobile } = useWindowSize()

  const { i18n: { useMessage } } = hooks
  const bubbleList = [
    {
      linkTo: `${routes.graph}`,
      icon: 'link',
      label: useMessage('landing.bubble.graph'),
      size: isMobile ? 110 : 140,
      email: false,
    },
    {
      linkTo: `${routes.meetings}`,
      icon: 'comments',
      label: useMessage('landing.bubble.meetings'),
      size: isMobile ? 110 : 140,
    },
    {
      linkTo: `${routes.documents}`,
      icon: 'cloud-download-alt',
      label: useMessage('landing.bubble.download'),
      size: isMobile ? 110 : 140,
    },
    {
      icon: 'envelope',
      label: useMessage('landing.bubble.feedback'),
      size: isMobile ? 110 : 140,
      mailTo: `${process.env.REACT_APP_FEEDBACK_MAIL}`,
    },
  ]


  return (
    <Layout>
      <Jumbotron />
      <Grid container alignItems="center" justify="center">
        <Grid item lg={4} style={{ textAlign: 'center' }}>
          <HomeLogo />
        </Grid>
        <Grid item lg={8}>
          <BubbleGrid bubbleList={bubbleList} />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '20px' }}>
          <YoutubeEmbed embedId="https://www.youtube.com/embed/cxGAX8BezMM?showinfo=0&controls=2&rel=0" />
        </Grid>
      </Grid>
    </Layout>
  )
}

export default LandingPage
