import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.error.dark,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  card: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  adjust: {
    alignSelf: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    display: 'flex',
    width: '100%',
    color: '#085986',
  },

  meetingName: {
    padding: '1rem',
    borderBottom: '1px solid #ccc',
    fontWeight: 'bold',
    color: '#085986',
    // background: '#085986',
  },
}))

export default useStyles
