import React from 'react'
import routes from 'pages/routes.json'
import { useAuth, hooks } from 'lq-ui'
import { Button, TextField } from '@material-ui/core'
import styles from './SignInFormStyles'

const SignInForm = () => {
  const classes = styles()
  const auth = useAuth()
  const { i18n: { useMessage }, router: { useRouter } } = hooks

  const router = useRouter()
  const [values, setValues] = React.useState({
    email: '',
    password: '',
    error: false,
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    const isAuth = await auth.signin(values.email, values.password)
    if (isAuth) {
      router.push(routes.editor)
    } else {
      setValues({ ...values, error: true })
    }
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value, error: false })
  }

  return (
    <form className={classes.form} noValidate onSubmit={handleSubmit}>
      <TextField
        type="email"
        value={values.email}
        label={useMessage('signin.label')}
        onChange={handleChange('email')}
        size="medium"
        variant="outlined"
        margin="normal"
        fullWidth
        required
        autoFocus
      />
      <TextField
        type="password"
        value={values.password}
        label={useMessage('signin.password')}
        onChange={handleChange('password')}
        variant="outlined"
        margin="normal"
        size="medium"
        fullWidth
        required
      />
      <Button
        fullWidth
        color="primary"
        className={classes.submit}
        onClick={handleSubmit}
        type="submit"
        variant="contained"
      >
        {useMessage('signin.label')}
      </Button>

      {values.error && (
      <span className={classes.validation}>
        Es tut mir leid, der Benutzername oder das Passwort stimmt leider nicht.
      </span>
      )}
    </form>
  )
}

export default SignInForm
