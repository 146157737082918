/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-undef */
const privacyStatement = {
  'header.0': 'DATENSCHUTZERKLÄRUNG',
  'header.1': '(Informationen nach Artikel 13 EU-Datenschutzgrundverordnung)',
  'paragraph.1': `Diese Datenschutzerklärung informiert Sie als Nutzerinnen und Nutzer der Internetangebote des Kreises Minden-Lübbecke über die Art, den Umfang und die Zwecke der Erhebung und Verwendung personenbezogener Daten durch die Behörde.

  Wenn Sie unsere Internetseiten besuchen, erbringt der Kreis Minden-Lübbecke als Anbieter für Sie einen Telemediendienst im Sinne des Telemediengesetzes. Dabei werden personenbezogene Daten im  Rahmen der gesetzlichen Bestimmungen, insbesondere der EU-Datenschutzgrundverordnung, des Bundesdatenschutzgesetzes und des Datenschutzgesetzes NRW verarbeitet.
  
  Im Folgenden informieren wir über die Erhebung personenbezogener Daten bei Nutzung unserer Webseiten. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind, z.B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.
  `,
  'header.2': `Verantwortlicher und Datenschutzbeauftragte`,
  'paragraph.2': `Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutzgrundverordnung (DS-GVO) ist der

  Kreis Minden-Lübbecke - Die Landrätin –
  Portastraße 13
  32423 Minden

Unsere Datenschutzbeauftragte erreichen Sie unter datenschutz@minden-luebbecke.de.
Sie können unsere Datenschutzbeauftragte auch unter der Tel.-Nr.: 0571/807-22020 oder der
Fax-Nr.: 0571/807-32020 oder unter der Postanschrift "Portastraße 13, 32423 Minden" mit dem
Zusatz „Die Datenschutzbeauftragte“ kontaktieren.
`,
  'header.3': `Ihre Rechte`,
  'paragraph.3': `Sie haben im Rahmen der gesetzlichen Bestimmungen folgende Rechte hinsichtlich der Sie betreffenden personenbezogenen Daten:

  Recht auf Auskunft
  Recht auf Berichtigung oder Löschung
  Recht auf Einschränkung der Verarbeitung
  Recht auf Widerspruch gegen die Verarbeitung
  Recht auf Datenübertragbarkeit
  
  Sie haben zudem das Recht, sich jederzeit an die Aufsichtsbehörde zu wenden, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DS-GVO verstößt.
  
  Die Kontaktdaten finden Sie hier:
  
  Landesbeauftragte für Datenschutz und Informationsfreiheit (LDI NRW)
  
  Postfach 20 04 44, 40102 Düsseldorf, Tel. 0211/38424-0, Fax: -10
  
  E-Mail: poststelle@ldi.nrw.de; Internet: www.ldi.nrw.de
  `,
  'header.4': `Allgemeine Hinweise zur Datenverarbeitung`,
  'header.5': `Rechtsgrundlage für die Verarbeitung personenbezogener Daten:`,
  'paragraph.5': `Unsere Behörde unterliegt bei der Verarbeitung Ihrer personenbezogenen Daten, gesetzlichen Bestimmungen, insbesondere der EU-Datenschutzgrundverordnung, dem Bundesdatenschutzgesetzt sowie dem Datenschutzgesetz NRW.

  Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 a DSGVO als Rechtsgrundlage. Die Einwilligung ist freiwillig und kann jederzeit Ihrerseits mit Wirkung für die Zukunft widerrufen werden. Hierbei können Sie die Erklärung des Widerrufes ebenfalls in Form einer elektronischen Nachricht übersenden. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
  `,
  'header.6': `Datenlöschung und Speicherdauer`,
  'paragraph.6': `Die personenbezogenen Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine Speicherung kann darüber hinaus erfolgen, wenn dies durch gesetzliche Vorgaben, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung oder Löschung der Daten erfolgt auch dann, wenn eine gesetzlich vorgeschriebene Speicherfrist abläuft, es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten besteht.`,
  'header.7': `Erhebung personenbezogener Daten bei Besuch unserer Webseiten`,
  'paragraph.7': `Bei der informatorischen Nutzung unserer Webseiten gelten die unter der Nr. 1 allgemeinen Hinweise zur Datenverarbeitung. Weitere Hinweise werden im Folgenden dargestellt. Wir erheben dabei nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt.
  `,
  'header.8': `Server-Logfiles`,
  'paragraph.8': `Bei dem Aufrufen unserer Webseiten übermittelt Ihr Browser in der Regel folgende Daten, die wir speichern, um die Stabilität und Sicherheit zu gewährleisten (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1
    lit. e DSGVO):
    
    IP-Adresse
    Name der abgerufenen Webseite
    Datei
    Datum und Uhrzeit der Anfrage
    Übertragene Datenmenge
    Meldung über erfolgreichen Abruf
    Browsertyp nebst Version
    Verwendetes Betriebssystem
    Referrer ULR
    
     
    
    Die Protokolldaten werden ausschließlich für statistische Auswertungen zum Zweck des Betriebs, der Sicherheit und der Optimierung des Angebotes verwendet.
    `,
  'header.9': `Kontaktformular und E-Mail-Kontakt`,
  'paragraph.9': `Bei einer Kontaktaufnahme per E-Mail oder über ein Web-Formular werden die von Ihnen mitgeteilten Daten (Ihre E-Mail Adresse, ggf. Ihr Name und Ihre Telefonnummer) gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungsfristen bestehen. 

  Die Verarbeitung der in ein Web-Formular eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 a DSGVO). Sofern es sich bei diesen Daten um personenbezogene Daten handelt, erfolgt die Übermittlung mit einem dem Stand der Technik entsprechenden Verschlüsselungsverfahren (zu erkennen an dem https vor der aufgerufenen Internet-Adresse).
  
  Sollten Sie dem Kreis Minden-Lübbecke elektronische Nachrichten mit vertraulichem oder rechtsverbindlichen Inhalt (z.B. mit personenbezogenen Daten in einem Antrag oder Widerspruch) zusenden wollen, bitten wir, die Hinweise zur so genannten Zugangseröffnung Impressum sowie zur (rechtsverbindlichen) elektronischen Kommunikation zu beachten.
  `,
  'header.10': `Nutzung und Weitergabe persönlicher Daten – Zweckbindung`,
  'paragraph.10': `Alle im Rahmen der Onlineangebote des Kreises Minden-Lübbecke von Ihnen übermittelten persönlichen Daten werden entsprechend der jeweils geltenden Vorschriften zum Schutz personenbezogener Daten genutzt. Diese Verarbeitung bezieht sich nur auf konkrete Zwecke, die zur Aufgabenerfüllung erforderlich sind.  Eine Weitergabe an Dritte findet nur insoweit statt, wenn es zur Erledigung der Aufgabe erforderlich ist, wenn es gesetzlich vorgegeben ist oder wenn Sie Ihr Einverständnis gegeben haben. Nähere Informationen zum Umfang der Verarbeitung finden Sie bei den jeweiligen Onlineangeboten.
  `,
  'header.11': `Hinweis zur Nutzung von InTool`,
  'paragraph.11': `Unsere Webseite https://intool-muehlenkreis.de ist für Sie kostenfrei und dient zur Information und Orientierung von Sprach- und Arbeitsförderangeboten im Kreis Minden-Lübbecke. Sie können sich durch Angebote navigieren, Dokumente herunterladen und Informationen zu Sprachtreffs einsehen. Über das Kontaktformular übermitteln Sie uns freiwillig Ihre Daten. Bitte beachten Sie hierbei o.g. Punkt Kontaktformular und E-Mail-Kontakt.

  Dieser Internetauftritt verwendet Cookies in Form von sogenannten “Session-Cookies”. In Session-Cookies werden nur Informationen über die aktuelle Sitzung gespeichert und nach Ende Ihres Besuchs automatisch gelöscht. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
  
  Cookies bzw. Session-Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von Ihnen gewünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert behandelt.
  `,
  'header.12': `Hinweis zur Nutzung von Matomo (früher: Piwik)`,
  'paragraph.12': `Unsere Webseite verwendet Matomo, dabei handelt es sich um einen sogenannten Webanalysedienst. Matomo verwendet sogenannte "Cookies", das sind Textdateien, die auf Ihrem Computer gespeichert werden und die unsererseits eine Analyse der Benutzung der Webseite ermöglichen. Zu diesem Zweck werden die durch den Cookie erzeugten Nutzungsinformationen (einschließlich Ihrer gekürzten IP-Adresse) an unseren Server übertragen und zu Nutzungsanalysezwecken gespeichert, was der Webseitenoptimierung unsererseits dient. Ihre IP-Adresse wird bei diesem Vorgang umge­hend anony­mi­siert, so dass Sie als Nutzerin beziehungsweise Nutzer für uns anonym bleiben. Die durch den Cookie erzeugten Informationen über Ihre Benutzung einer Webseite werden nicht an Dritte weitergegeben. Sie können die Verwendung der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern, es kann jedoch sein, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite voll umfänglich nutzen können. Wenn Sie mit der Speicherung und Auswertung dieser Daten aus Ihrem Besuch nicht einverstanden sind, dann können Sie der Speicherung und Nutzung nachfolgend per Mausklick jederzeit widersprechen. In diesem Fall wird in Ihrem Browser ein sogenanntes Opt-Out-Cookie abgelegt, was zur Folge hat, dass Matomo keinerlei Sitzungsdaten erhebt.

  Wenn Sie Ihre Cookies löschen, so hat dies zur Folge, dass auch das Opt-Out-Cookie gelöscht wird und gegebenenfalls von Ihnen erneut aktiviert werden muss.
  `,
  'header.13': `Hinweise zur Datenerfassung im Gremieninfosystem und den mobilen RICH-Clients
  `,
  'header.14': `Cookies`,
  'paragraph.14': `Die vom Gremieninformationssystem verwendeten Cookies sind so genannte "Session-Cookies". Sie werden nach Ende Ihres Besuchs automatisch gelöscht. Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.
  `,
  'header.15': `Notizen`,
  'paragraph.15': `Wenn Sie über die verschiedenen Notizfunktionen Notizen erfassen und auch ggf. mit anderen Personen teilen, werden diese Angaben bei uns gespeichert. Die Speicherung erfolgt zunächst in der Anwendung, in der die Notizen eingegeben wurden. Also dem Gremieninfosystem oder dem mobilen RICH-Client. Bei der nächsten Online-Verbindung der Systeme, in Verbindung mit Ihrer erfolgreichen Authentifikation, werden diese Informationen zwischen dem Gremieninfosystem und dem RICH-Client synchronisiert.

  Die Speicherung sowie die Synchronisation der Notizdaten erfolgt verschlüsselt, so dass ein Zugriff, auch für die Systemadministration, ausgeschlossen ist.
  `,
  'header.16': `Akte/Fraktionsarbeit - Dateiupload`,
  'paragraph.16': `Wenn Sie den Dateiupload aus den Zusatzmodulen Akte bzw. Fraktionsarbeit nutzen, werden diese Dateien bei uns gespeichert. Bei der nächsten Online-Verbindung der Systeme, in Verbindung mit Ihrer erfolgreichen Authentifikation, werden diese Informationen zwischen dem Gremieninfosystem und den RICH-Clients synchronisiert.

  Die Speicherung sowie die Synchronisation der Dateien erfolgt verschlüsselt, so dass ein Zugriff, auch für die Systemadministration, ausgeschlossen ist.
  `,
  'header.17': `Nutzung der BürgerApp`,
  'paragraph.17': `Für die Nutzung der BürgerApp ist eine Registrierung mit einer E-Mail-Adresse als Loginname und Passwort erforderlich. Der mit diesen Informationen erzeugte Account wird im Gremieninfosystem angelegt und gespeichert. Zur Aktivierung wird ein Aktivierungslink an die eingegebene E-Mail-Adresse gesendet.

  Die Eingabe weiterer Kontaktdaten ist nicht erforderlich bzw. möglich.
  `,
  'header.18': `Deaktivierung des Web-Trackings`,
  'paragraph.18': `Wenn Sie sich entscheiden, dass Ihr Besuch nicht mehr erfasst werden soll, wird das Web-Tracking auf den folgenden Internetseiten deaktiviert.

  Sie haben das Tracking in Ihrem Browser deaktiviert.
  
  Ihr Besuch auf dieser Website wird nicht statistisch erfasst, da Ihr Browser uns mitgeteilt hat, dass Sie kein Tracking wünschen.
  
  Um das Tracking zu aktivieren, müssen Sie die sogenannte "Do Not Track"-Einstellung in Ihrem Browser deaktivieren.
  `,
  'header.19': `Haftungsausschluss`,
  'paragraph.19': `Die auf unseren Webseiten vorhandenen Links zu fremden Anbietenden wurden nach bestem Wissen und Gewissen erstellt. Dabei wurde auf die Vertrauenswürdigkeit der jeweiligen Anbietenden geachtet. Eine Einzelfallprüfung sämtlicher Inhalte ist nicht in jedem Fall möglich. Wir distanzieren uns daher ausdrücklich von allen Inhalten sämtlicher gelinkter Webseiten und machen uns die fremden Inhalte nicht zu eigen.

  Wir bitten die Leserinnen und Leser, uns umgehend auf etwaige rechtswidrige Inhalte der gelinkten Webseiten aufmerksam zu machen. Vielen Dank!
  `,
}

export default privacyStatement
