import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { hooks } from 'lq-ui'
import { muehlenkreisLogo } from 'assets/images'
// import useWindowSize from 'hooks/useWindowSize'
import useStyles from './styles'


const Jumbotron = () => {
  const classes = useStyles()
  const { i18n: { useMessage } } = hooks
  return (
    <Grid container justify="center" className={classes.root}>
      <Grid item>
        <Grid container alignItems="center">
          <Typography variant="h4" color="primary" component="h1" className={classes.mainText} align="center">
            {useMessage('landing.heading.main')}
            <img src={muehlenkreisLogo} alt="Muehlen Kreis" className={classes.image} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Jumbotron
