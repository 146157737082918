import { useState, useEffect } from 'react'

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: (window.innerWidth <= 414 && window.innerHeight <= 823)
    || (window.innerWidth <= 823 && window.innerHeight <= 414),

  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: (window.innerWidth <= 414 && window.innerHeight <= 823)
    || (window.innerWidth <= 823 && window.innerHeight <= 414),
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  return windowSize
}

export default useWindowSize
