import React from 'react'
import { useSelector } from 'react-redux'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent'
import { Grid, Button } from '@material-ui/core'
import { meetingsFilterOptions } from 'consts/meetingsFilter'
import { Switch, Route } from 'react-router-dom'
import { hooks } from 'lq-ui'
import useWindowSize from 'hooks/useWindowSize'
import AddIcon from '@material-ui/icons/Add'
import styles from './styles'
import MeetingForm from './MeetingForm'
import MeetingsFilter from './MeetingsFilter'
import MeetingsList from './MeetingsList'
import MeetingsMenu from './MeetingsMenu'

const Meetings = () => {
  const classes = styles()
  const { graph: { meetings }, appState: { status }, proposals } = useSelector((state) => state)
  const [selectedMeeting, setSelectedMeeting] = React.useState(null)
  const { match: { path } } = hooks.router.useRouter()
  const currentPath = path.substring(1)
  const { isMobile } = useWindowSize()

  return (
    <>
      {status === 2
        ? (
          <>
            {isMobile
            && (
            <Grid container justify="space-between" className={classes.mobileMenu}>
              <MeetingsMenu
                filterItems={meetingsFilterOptions}
                addNewMeeting={() => setSelectedMeeting({})}
              />
              <Button
                color="primary"
                variant="contained"
                onClick={() => setSelectedMeeting({})}
              >
                <AddIcon />
              </Button>
            </Grid>
            )}
            <Grid container className={classes.root} alignItems="stretch">
              {!isMobile && (
              <Grid item sm={2} className={classes.menu}>
                <MeetingsFilter
                  filterItems={meetingsFilterOptions}
                  addNewMeeting={() => setSelectedMeeting({})}
                />
              </Grid>
              )}
              <Grid item sm={isMobile ? 12 : 10}>
                <Switch>
                  <Route exact path={path}>
                    <MeetingsList
                      meetings={currentPath === 'meetings' ? meetings : proposals}
                      onSelect={(value) => setSelectedMeeting(value)}
                      isProposal={currentPath === 'proposals'}
                    />
                  </Route>
                  <Route path={`${path}/:district`}>
                    <MeetingsList
                      meetings={currentPath === 'meetings' ? meetings : proposals}
                      onSelect={(value) => setSelectedMeeting(value)}
                      isProposal={currentPath === 'proposals'}
                    />
                  </Route>
                </Switch>

              </Grid>
            </Grid>
          </>
        )
        : <LoadingSpinnerView />}
      {selectedMeeting && (
      <MeetingForm
        id={selectedMeeting.id}
        onClose={() => setSelectedMeeting(null)}
        isProposal={currentPath === 'proposal'}
      />
      )}
    </>

  )
}

export default Meetings
