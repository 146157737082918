import React from 'react'
import MuiAutocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'

const filter = createFilterOptions()
function Autocomplete({
  options, onChange, value, name,
}) {
  return (
    <MuiAutocomplete
      multiple
      id={name}
      options={options}
      getOptionLabel={(option) => option.title}
      filterOptions={(items, params) => {
        const filtered = filter(items, params)
        return filtered
      }}
      filterSelectedOptions
      onChange={(e, val) => onChange(val)}
      renderOption={
        (option) => (
          <>
            <Checkbox
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              style={{ marginRight: 8 }}
              checked={value.some((o) => o.value === option.value)}
            />
            {option.title}
          </>
        )
      }
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={name}
        />
      )}
      disableCloseOnSelect
    />
  )
}

Autocomplete.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default Autocomplete
