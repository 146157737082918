import React from 'react'
import PropTypes from 'prop-types'
import {
  Select as MuiSelect, FormControl, InputLabel, MenuItem,
} from '@material-ui/core'

const Select = ({
  options, value, label, onChange, name, error,
}) => (
  <FormControl
    variant="outlined"
    fullWidth
    margin="normal"
    error={error}
  >
    <InputLabel id={`label-for-${name}`}>{label}</InputLabel>
    <MuiSelect
      labelId={`label-for-${name}`}
      id={name}
      value={value}
      onChange={onChange}
      label={label}
    >
      {options.map((option) => <MenuItem value={option.value} key={option.value}>{option.label}</MenuItem>)}
    </MuiSelect>
  </FormControl>
)

Select.defaultProps = {
  error: '',
  options: [],
}

Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape(
      { label: PropTypes.string.isRequired, value: PropTypes.string.isRequired },
    ),
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
}

export default Select
