import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '@material-ui/core'
import PropTypes from 'prop-types'

const InputField = ({ defaultValue, field }) => {
  const { control } = useFormContext()

  return (
    <Controller
      control={control}
      name={field.name}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value },
        fieldState: { error },
      }) => (
        <TextField
          value={value}
          label={field.label}
          onChange={onChange}
          variant="outlined"
          rows={field.rows}
          fullWidth
          margin="normal"
          multiline={field.rows}
          error={error}
          helperText={error?.message}
        />
      )}
    />
  )
}


InputField.propTypes = {
  defaultValue: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
}
export default InputField
