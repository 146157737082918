import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Paper, Card, Heading } from 'lq-ui'
import { Button, Typography } from '@material-ui/core'
import { resetCriteriaValues, selectNode } from 'store/actions'
import { computeNextCondition } from 'store/selectors'

import NodeConditions from 'components/GraphView/NodeConditions'

import Persona from 'components/GraphView/Persona'
import useStyles from './ConfiguratorBoxStyles'

const ConfiguratorBox = () => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(selectNode(0))
    dispatch(resetCriteriaValues({}))
  }
  const { conditions } = useSelector((state) => state.appState)
  const classes = useStyles()
  const nextPersonaCondition = useSelector(computeNextCondition)

  return (
    <Paper className={classes.paper}>
      {!Object.keys(conditions).length
        && (
          <Card className={classes.card}>
            <Card.Content>
              <Typography color="primary">
                Bitte konfigurieren Sie eine Persona, um die individuelle Bildungskette anzuzeigen
              </Typography>
            </Card.Content>
          </Card>
        )}

      {Object.keys(conditions).length > 0
        && (
          <Button
            color="secondary"
            className={classes.button}
            variant="contained"
            onClick={() => handleClick()}
          >
            Persona zurücksetzen
          </Button>
        )}
      {(!!nextPersonaCondition || !!Object.keys(conditions).length)
        && <Heading level="h6" color="#3A6311">Bedingungen</Heading>}
      {!!nextPersonaCondition
        && <Persona />}
      {
        !nextPersonaCondition
        && <NodeConditions />
      }
    </Paper>
  )
}

export default ConfiguratorBox
