import React from 'react'
import {
  Button,
  Card, Typography, CardContent, CardActions, IconButton,
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { nodeSelector } from 'store/selectors'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Heading } from 'lq-ui'
import { updateNodeInformation } from 'store/actions'
import ExclusionCriteriaForm from './ExclusionCriteriaForm'

const formatValue = (v) => {
  if (typeof v === 'string') {
    return v
  }
  return v ? 'Ja' : 'Nein'
}

const NodeExclusionCriteria = () => {
  const [criteria, serCriteria] = React.useState(false)
  const nodeId = useSelector(nodeSelector)
  const { nodes } = useSelector((state) => state.graph)
  const node = nodes.find((n) => n.id === nodeId)
  const dispatch = useDispatch()
  const onDelete = (criteriaIndex) => {
    const exclusionCriteria = node.exclusionCriteria.filter((c, i) => criteriaIndex !== i)
    const nodeData = {
      ...node,
      exclusionCriteria,
    }
    dispatch(updateNodeInformation(nodeData))
  }
  return (
    <div style={{ marginTop: '1rem' }}>
      <Heading level="h6" color="#3A6311">
        Ausschluss Kriterien
      </Heading>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => serCriteria({
          criteriaSet: {},
          criteriaIndex: null,
        })}
      >
        Neue Ausnahme
      </Button>
      {node && node.exclusionCriteria.length > 0
      && (node.exclusionCriteria.map((ec, index) => (
        <Card key={Object.keys(ec)[0]} style={{ marginTop: '1rem' }}>
          <CardContent>
            {!!Object.keys(ec).length && (
            <>
              {
            Object.keys(ec).map((key) => (
              <div key={key}>
                <Typography component="h6" variant="subtitle2" display="inline">
                  {key}
                </Typography>
                {ec[key].map((c) => (
                  <ul>
                    <li>{formatValue(c)}</li>
                  </ul>
                ))}
              </div>
            ))
            }
            </>
            ) }
          </CardContent>
          <CardActions>
            <IconButton onClick={() => serCriteria({
              criteriaSet: ec,
              criteriaIndex: index,
            })}
            >
              <EditIcon color="primary" />
            </IconButton>
            <IconButton onClick={() => onDelete(index)}>
              <DeleteIcon color="error" />
            </IconButton>
          </CardActions>

        </Card>
      )))}
      {criteria && (
      <ExclusionCriteriaForm
        onClose={() => serCriteria(false)}
        criteriaSet={criteria.criteriaSet}
        criteriaIndex={criteria.criteriaIndex}
      />
      )}

    </div>
  )
}

export default NodeExclusionCriteria
