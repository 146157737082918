import { makeStyles } from '@material-ui/core/styles'
import useWindowSize from 'hooks/useWindowSize'

const useStyles = () => {
  const { isMobile } = useWindowSize()

  const styles = makeStyles((theme) => ({
    paper: {
      margin: isMobile ? 0 : theme.spacing(1),
      padding: theme.spacing(isMobile ? 1 : 2),
    },
    button: {
      borderRadius: '4px',
      padding: '6px 8px',
      marginBottom: '.5rem',
    },
  }))

  return styles()
}

export default useStyles
