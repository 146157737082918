// const createImage = () => {
//   const svg = `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="user" class="svg-inline--fa
//   fa-user fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
//   <path fill="currentColor"
//   d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9
//   16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0
//   48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
//   '<text x="30" y="80" style="font-size:60px;fill:#000000;">jjjjj</text>' +
//   </svg>`

//   return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`
// }

const options = {
  edges: {
    // arrowStrikethrough: true,
    chosen: true,
    color: {
      color: '#3A6311',
      highlight: '#e1eab1',
      hover: '#3A6311',
      inherit: 'from',
      opacity: 0.6,
    },
    dashes: false,
    hidden: false,
    hoverWidth: 1.5,
    // length: undefined,
    physics: true,
    scaling: {
      min: 1,
      max: 1,
      // label: {
      //   enabled: true,
      //   min: 1,
      //   max: 1,
      //   maxVisible: 30,
      // },
    },
    selectionWidth: 5,
    selfReferenceSize: 20,
    smooth: {
      enabled: true,
      type: 'dynamic',
      roundness: 0.5,
    },
    title: undefined,
    value: undefined,
    width: 1,
    widthConstraint: false,
  },
  layout: {
    hierarchical: {
      direction: 'LR',
      sortMethod: 'directed',
    },
  },
  physics: {
    hierarchicalRepulsion: {
      avoidOverlap: 50,
    },
  },
  nodes: {
    font: { strokeWidth: 0, background: 'white' },

    widthConstraint: {
      maximum: 150,
    },
    borderWidthSelected: 5,
    fixed: {
      x: false,
      y: true,
    },
    chosen: true,
    margin: 10,
    scaling: {
      min: 100,
      max: 100,
      label: {
        enabled: false,
        min: 1,
        max: 50,
        maxVisible: 50,
        drawThreshold: 0,
      },
    },
  },
  interaction: {
    zoomView: true,
    dragNodes: false,
    zoomSpeed: 0.2,
  },
  groups: {
    gate: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf1ad',
        color: '#3A6311',
      },
    },
    course: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf51c',
        color: '#3A6311',
      },
    },
    start: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf21d',
        color: '#3A6311',
      },
    },
    end: {
      shape: 'icon',
      icon: {
        face: "'Font Awesome 5 Pro'",
        weight: 'bold', // Font Awesome 5 doesn't work properly unless bold.
        code: '\uf11e',
        color: '#3A6311',
      },
    },
  },
}


export default options
