import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Container } from '@material-ui/core'
import PropTypes from 'prop-types'

import Footer from '../components/Footer'
import useStyles from './styles'


const Layout = ({ children }) => {
  const classes = useStyles()


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Container component="main" className={classes.main} maxWidth="xl">
        <>
          {children}
        </>
      </Container>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
