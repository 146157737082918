import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { createTheme, Root } from 'lq-ui'
import GraphPage from 'pages/GraphPage'
import LandingPage from 'pages/LandingPage'
import ImpressumPage from 'pages/ImpressumPage'
import MeetingsPage from 'pages/MeetingsPage'
import SignInPage from 'pages/Signin'
import EditorPage from 'pages/EditorPage'
import DocumentsPage from 'pages/DocumentsPage'
import pages from 'pages/routes.json'
import ReduxProvider from 'store/ReduxProvider'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import Confirmation from 'components/Confirmation'
import messages from 'messages/de.json'


library.add(fas)

const theme = createTheme({
  palette: {
    primary: {
      main: '#3A6311',
    },
    secondary: {
      main: '#e1eab1',
    },
  },

})

const App = () => {
  document.body.style.backgroundColor = '#fff'
  const routes = [
    { path: pages.root, component: LandingPage, exact: true },
    { path: pages.graph, component: GraphPage, exact: true },
    { path: pages.signin, component: SignInPage, exact: true },
    { path: pages.editor, component: EditorPage, exact: true },
    { path: pages.meetings, component: MeetingsPage, exact: false },
    { path: pages.proposals, component: MeetingsPage, exact: false },
    { path: pages.documents, component: DocumentsPage, exact: true },
    { path: pages.impressum, component: ImpressumPage, exact: true },
  ]
  return (
    <ReduxProvider>
      <Root theme={theme} auth="backend" message={messages}>
        <Router>
          <Switch>
            {routes.map(({ path, component, exact }) => (
              <Route
                exact={exact}
                path={path}
                component={component}
                key={path}
              />
            ))}
          </Switch>
        </Router>
        <Confirmation />
      </Root>
    </ReduxProvider>
  )
}

export default App
