import React from 'react'
import GraphContainer from 'components/Graph'
import NodeInfoView from 'components/GraphView/NodeInfoView'
import GraphLegend from 'components/GraphLegend'
import { usePrint, PrintButton, PrintView } from 'lq-ui'
import { Grid } from '@material-ui/core'
import NodeProviders from 'components/GraphView/NodeProviders'
import NodeInformation from 'components/GraphView/NodeInformation'
import { useDispatch, useSelector } from 'react-redux'
import { nodeSelector } from 'store/selectors'
import { selectNode } from 'store/actions'
import useNodeInfo from 'hooks/useNodeInfo'
import ConfiguratorBox from 'components/GraphView/ConfiguratorBox'
import useWindowSize from 'hooks/useWindowSize'
import styles from './GraphConfigurationStyles'
import PrintGraph from './GraphPrint'

const GraphConfiguration = () => {
  const dispatch = useDispatch()

  const [print, setPrint] = usePrint()
  const node = useSelector(nodeSelector)
  const [, , providers] = useNodeInfo()
  const { isMobile } = useWindowSize()

  const classes = styles(node)
  return (
    <>
      <Grid container className={classes.container}>
        {(!isMobile || !node) && (
        <Grid item xs={4} md={3} className={classes.rightSideNavContainer}>
          <ConfiguratorBox />
        </Grid>
        )}
        <Grid item xs={8} md={node ? 6 : 9} className={classes.graphContainer}>
          <div className={classes.legends}>
            <PrintButton handlePrint={setPrint} />
            <GraphLegend />
          </div>
          <GraphContainer className={classes.graph} />
          {(!!node && !!providers.length) && <NodeProviders />}
        </Grid>
        {!!node
          && (
            <Grid item xs={4} md={3} className={classes.leftSideNavContainer}>
              <NodeInformation handleClose={() => dispatch(selectNode(0))} />
            </Grid>
          )}
      </Grid>
      {print && <PrintGraph />}
      {print && node && (
      <PrintView>
        <NodeInfoView />
        <NodeProviders print />
      </PrintView>
      )}
    </>
  )
}

export default GraphConfiguration
