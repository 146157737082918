import React from 'react'
import { hooks } from 'lq-ui'
import LegendItem from './GraphLegendItem'
import styles from './GraphLegendStyles'

const GraphLegend = () => {
  const classes = styles()
  const legends = [
    {
      color: '#3A6311',
      label: hooks.i18n.useMessage('graph.legend.persona.label'),
      offset: 0.2,
      iconClass: 'street-view',
    },
    {
      color: '#3A6311',
      label: hooks.i18n.useMessage('graph.legend.institution.label'),
      iconClass: 'building',
    },
    {
      color: '#3A6311',
      label: hooks.i18n.useMessage('graph.legend.offer.label'),
      iconClass: 'chalkboard-teacher',
    },

  ]
  return (
    <div className={classes.toolbar}>
      {
        legends.map(
          (legend) => (
            <LegendItem
              key={legend.label}
              color={legend.color}
              label={legend.label}
              offset={legend.offset || 0.0}
              iconClass={legend.iconClass}
            />
          ),
        )
      }
    </div>
  )
}

export default GraphLegend
