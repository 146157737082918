import React from 'react'
import {
  Button, Menu, MenuItem, Divider,
} from '@material-ui/core'
import { hooks } from 'lq-ui'
import PropTypes from 'prop-types'
import { formParams, resolveParams } from 'helpers/urlParams'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import { useLocation } from 'react-router-dom'
import FilterIcon from '@material-ui/icons/FilterList'

const MeetingsMenu = ({ filterItems }) => {
  const router = hooks.router.useRouter()
  const { match: { path } } = router
  const fullUrl = useLocation()
  const currentPath = path.substring(1)

  const setRoute = (item) => (['proposals', 'meetings'].includes(item)
    ? `/${item}`
    : `${path}/${formParams(item)}`)

  const district = resolveParams(fullUrl.pathname.substring(path.length + 1))

  const { user } = useAuth()


  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const onItemClick = (item) => {
    if (item === 'Alle') {
      router.push(path)
      handleClose()
    } else {
      router.push(setRoute(item))
      handleClose()
    }
  }
  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<FilterIcon />}
        color="secondary"
        variant="contained"
      >
        {district ? filterItems.find((i) => i.toLowerCase() === district) : 'Alle'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {['Alle', ...filterItems.sort()].map((item) => (
          <MenuItem onClick={() => onItemClick(item)}>{item}</MenuItem>
        ))}
        {user && (
          <>
            <Divider />
            <MenuItem onClick={() => (
              router.push(currentPath === 'meetings' ? '/proposals' : '/meetings'))}
            >
              {currentPath === 'meetings' ? 'Vorschläge' : 'Sprachgelegenheiten'}
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  )
}

MeetingsMenu.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default MeetingsMenu
