import React from 'react'
import { useRequireAuth } from 'lq-ui'
import Editor from 'components/GraphEditorComponents/GraphEditor/Editor'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent/LoadingSpinnerView'
import Layout from 'pages/layouts/General'

const EditorPage = () => {
  const auth = useRequireAuth()
  if (!auth.user) {
    return <LoadingSpinnerView />
  }

  return (
    <Layout title="Admin für Sprachkurse">
      <Editor />
    </Layout>
  )
}

export default EditorPage
