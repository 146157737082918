import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    checked: {},
    direction: {
      flexDirection: 'row',
    },
    font: {
      fontSize: 'small',
    },
    label: {
      paddingLeft: '13px',
    },
    root: {
      color: '#3A6311 !important',
      '&$checked': {
        color: '#3A6311 !important',
      },
    },
  })
  return useStyles()
}
