import React from 'react'
import PropTypes from 'prop-types'
import Radio from 'components/GraphEditorComponents/Radio'

const items = [{ label: 'Wählen', value: 'select' }, { label: 'Binär', value: 'bool' }]

const ValueTypeSelector = ({ value, onChange }) => (
  <Radio label="Werttype" value={value} onChange={onChange} items={items} />
)

ValueTypeSelector.defaultProps = {
  onChange: null,
  value: null,
}

ValueTypeSelector.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default ValueTypeSelector
