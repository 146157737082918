import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogActions, Button, Grid, DialogTitle, DialogContent,
} from '@material-ui/core'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { Autocomplete, MultiSelect } from 'components/FormFields'
import { getAllConditions } from 'services/graph'
import { useSelector, useDispatch } from 'react-redux'
import { nodeSelector } from 'store/selectors'
import { updateNodeInformation } from 'store/actions'

const ExclusionCriteriaForm = ({ onClose, criteriaSet, criteriaIndex }) => {
  const nodeId = useSelector(nodeSelector)
  const { nodes, edges } = useSelector((state) => state.graph)
  const node = nodes.find((n) => n.id === nodeId)

  const dispatch = useDispatch()

  const methods = useForm({
    defaultValues: {
      exclusionCriteria: Object.keys(criteriaSet).length
        ? Object.keys(criteriaSet).map((i) => ({ title: i, value: i }))
        : [],
    },
  })

  const { handleSubmit, watch, control } = methods

  const onSubmit = (data) => {
    const { exclusionCriteria, ...rest } = data
    const exclusionCriteriaValues = exclusionCriteria.reduce((o, c) => ({ ...o, [c.value]: rest[c.value] }), {})

    const nodeExclusionCriteria = node.exclusionCriteria || []
    const nodeData = {
      ...node,
      exclusionCriteria: criteriaIndex === null
        ? (exclusionCriteria.length
          ? [...nodeExclusionCriteria, exclusionCriteriaValues]
          : nodeExclusionCriteria)
        : (exclusionCriteria.length
          ? [...nodeExclusionCriteria.filter((c, i) => i !== criteriaIndex), exclusionCriteriaValues]
          : nodeExclusionCriteria.filter((c, i) => i !== criteriaIndex)),
    }
    dispatch(updateNodeInformation(nodeData))
    onClose()
  }

  const { exclusionCriteria } = watch()
  const conditions = getAllConditions(edges)
  const conditionsOptions = Object.keys(conditions).map((c) => ({ title: c, value: c }))
  const conditionOptions = (c) => (conditions[c].type === 'bool'
    ? conditions[c].items.map((i) => ({ label: i, value: i === 'Ja' }))
    : conditions[c].items.map((i) => ({ label: i, value: i })))

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Neue Ausnahme</DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <DialogContent>
            <Grid container>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="exclusionCriteria"
                  render={({
                    field: { onChange, value, name },
                  }) => (
                    <Autocomplete
                      options={conditionsOptions}
                      onChange={(v) => onChange(v)}
                      value={value}
                      name={name}
                    />
                  )}
                />
              </Grid>
              {exclusionCriteria && exclusionCriteria.map((c) => (
                <Grid item xs={12} key={c.value}>
                  <Controller
                    control={control}
                    name={c.value}
                    render={({
                      field: { onChange, value, name },
                    }) => (
                      <MultiSelect
                        value={value}
                        onChange={onChange}
                        options={conditionOptions(c.value)}
                        label={name}
                        name={name}
                      />
                    )}
                    defaultValue={exclusionCriteria[c.value] || criteriaSet[c.value] || []}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="secondary"
              variant="contained"
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              Speichern
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}

ExclusionCriteriaForm.defaultProps = {
  criteriaIndex: null,
}

ExclusionCriteriaForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  criteriaSet: PropTypes.object.isRequired,
  criteriaIndex: PropTypes.number,
}

export default ExclusionCriteriaForm
