import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Container } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { loadGraph, loadProposals } from 'store/actions'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import Footer from '../components/Footer'
import useStyles from './styles'
import Header from '../components/Header'

const Layout = ({ children, title }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { appState: { status } } = useSelector((state) => state)

  React.useEffect(() => {
    if (!status) {
      if (user) dispatch(loadProposals())

      dispatch(loadGraph())
    }
  }, [dispatch, status, user])


  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header title={title} />
      <Container component="main" className={classes.main} maxWidth="xl">
        {children}
      </Container>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

Layout.defaultProps = {
  title: 'inTool',
}

export default Layout
