/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropType from 'prop-types'
import Checkbox from '@material-ui/core/Checkbox'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { makeStyles } from '@material-ui/core/styles'

const Option = ({ option, value, checked }) => {
  const useStyles = makeStyles({
    option: {
      width: '100%',
      wordBreak: 'break-word',
    },
    checkbox: {
      marginRight: 8,
    },
    checkedColor: {
      color: '#3A6311',
    },
    bold: {
      fontWeight: 700,
    },
    light: {
      fontWeight: 400,
    },
  })

  const classes = useStyles()
  const matches = match(option, value)
  const parts = parse(option, matches)
  return (
    <div className={classes.option}>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
        checkedIcon={<CheckBoxIcon className={classes.checkedColor} fontSize="small" />}
        className={classes.checkbox}
        checked={checked}
      />
      {parts.map((part, index) => (
        <span
          key={index}
          className={part.highlight ? classes.bold : classes.light}
        >
          {part.text.word || part.text}
        </span>
      ))}
    </div>
  )
}

Option.propTypes = {
  option: PropType.oneOfType([PropType.string, PropType.object]).isRequired,
  value: PropType.string.isRequired,
  checked: PropType.bool.isRequired,
}

export default Option
