import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'block',
    color: theme.palette.primary.main,
  },
  impressumContent: {
    align: 'center',
    maxWidth: '80%',
    marginTop: '4rem',
    marginLeft: '2rem',
    whiteSpace: 'pre-line',
  },
}))
export default useStyles
