import { makeStyles } from '@material-ui/core/styles'

const useStyles = (size) => {
  const styles = makeStyles((theme) => ({
    root: {
      width: `${size}px`,
      height: `${size}px`,
      borderWidth: theme.spacing(0.5),
      borderColor: theme.palette.grey[50],
      borderStyle: 'solid',
    },
    icon: {
      display: 'block',
    },
    text: {
      textTransform: 'none',
      fontWeight: theme.typography.fontWeightBold,
    },
  }))
  return styles()
}
export default useStyles
