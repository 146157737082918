import React from 'react'
import PropTypes from 'prop-types'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
// import ConfirmEdgeChanges from 'components/EditorComponents/GraphEditor/ConfirmEdgeChanges'
import { useGraphForm } from 'components/GraphEditorComponents/GraphEditor/FormContex/useForm'
import { getAllConditions } from 'services/graph'
import BoolConditionEditor from './BoolConditionEditor'
import StringConditionEditor from './StringConditionEditor'
import ValueTypeSelector from './ValueTypeSelector'
import styles from './ConditionEditorStyles'

const ConditionEditor = ({
  condition,
  edge,
}) => {
  const classes = styles()
  const { state, actions: { updateEdgeCondition, removeEdgeCondition } } = useGraphForm()
  const conditionValues = getAllConditions(state.edges)[condition]
  // const [open, setOpen] = React.useState(false)

  const selectItems = conditionValues && conditionValues.type === 'bool'
    ? conditionValues?.items?.map((i) => ({ label: i, value: (i === 'Ja') }))
    : conditionValues?.items?.map((i) => ({ label: i, value: i }))

  const [valueType, setValueType] = React.useState('select')

  const onChange = (v) => {
    if (v === null || v === undefined) {
      removeEdgeCondition({ condition, edge })
    } else {
      updateEdgeCondition({
        value: v,
        condition,
        edge,
      })
    }
  }


  return (
    <>
      {/* {open && <ConfirmEdgeChanges data={open} onClose={() => setOpen(false)} />} */}
      <Card variant="outlined" className={classes.top}>
        <CardContent>
          {!conditionValues && (
          <ValueTypeSelector
            value={valueType}
            onChange={(v) => setValueType(v)}
            newCondition={condition}
          />
          )}

          {selectItems
            ? conditionValues.type === 'bool' ? (
              <BoolConditionEditor
                label={condition}
                value={edge[condition]}
                handleChangeCondition={onChange}
              />
            ) : (
              <StringConditionEditor
                conditionLabel={condition}
                value={edge[condition]}
                handleChangeCondition={onChange}
                selectItems={selectItems}
              />
            )
            : valueType === 'bool'
              ? (
                <BoolConditionEditor
                  label={condition}
                  value={edge[condition]}
                  handleChangeCondition={onChange}
                />
              )
              : (
                <StringConditionEditor
                  conditionLabel={condition}
                  value={edge[condition]}
                  handleChangeCondition={onChange}
                  selectItems={selectItems}
                />
              )}
        </CardContent>
      </Card>
    </>
  )
}

ConditionEditor.propTypes = {
  condition: PropTypes.string.isRequired,
  edge: PropTypes.object.isRequired,
}

export default ConditionEditor
