import React from 'react'
import {
  AppBar, Toolbar, Grid, Button, Link, Typography,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { intoolLogo } from 'assets/images'
import PropTypes from 'prop-types'
import { useAuth, hooks } from 'lq-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pages from 'pages/routes.json'
import useWindowSize from 'hooks/useWindowSize'
import useStyles from './styles'

const Header = ({ title }) => {
  const classes = useStyles()
  const { router: { useRouter }, i18n: { useMessage } } = hooks
  const router = useRouter()
  const auth = useAuth()
  const buttonLable = useMessage('signout.label')
  const { isMobile } = useWindowSize()

  const onSignout = () => {
    auth.signout()
    router.push(pages.root)
  }


  return (
    <AppBar position="static">
      <Toolbar>
        <Grid container justify="space-between">
          <Link component={RouterLink} to="/" className={classes.link}>
            <img src={intoolLogo} alt="inTool" className={classes.logo} />
          </Link>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {auth.user
            && (
            <Button
              color="primary"
              variant="contained"
              onClick={onSignout}
            >
              {!isMobile && buttonLable}
              <FontAwesomeIcon
                icon="sign-out-alt"
                size="1x"
                className={classes.signoutButton}
              />
            </Button>
            )}
        </Grid>
      </Toolbar>
    </AppBar>

  )
}

Header.propTypes = {
  title: PropTypes.string,
}

Header.defaultProps = {
  title: 'inTool',
}

export default Header
