import React from 'react'
import NodeInfoView from 'components/GraphView/NodeInfoView'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Paper } from 'lq-ui'
import CloseIcon from '@material-ui/icons/Close'
import styles from './NodeInformationStyles'

const NodeInfo = ({ handleClose }) => {
  const classes = styles()
  // const dispatch = useDispatch()
  // const handleClick = () => dispatch(selectNode(0))
  return (
    <Paper className={classes.paper}>
      <Button onClick={handleClose} variant="contained" color="secondary" className={classes.button}>
        Schließen
        <CloseIcon />
      </Button>
      <NodeInfoView />
    </Paper>
  )
}

NodeInfo.propTypes = {
  handleClose: PropTypes.func.isRequired,
}


export default NodeInfo
