import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { resolveParams } from 'helpers/urlParams'
import useWindowSize from 'hooks/useWindowSize'
import MeetingView from '../MeetingCard'
import styles from '../styles'


const sortByName = (a, b) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1
  } if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1
  }
  return 0
}

const MeetingsList = ({ meetings, onSelect, isProposal }) => {
  const { isMobile } = useWindowSize()
  const { district } = useParams()
  const classes = styles()

  const filteredMeetings = district
    ? meetings.filter((meeting) => meeting.district.toLowerCase() === resolveParams(district))
    : meetings


  return (
    <Grid
      container
      spacing={2}
      className={classes.meetings}
      alignItems="stretch"
      justify={isMobile ? 'center' : 'start'}
    >
      {filteredMeetings.sort(sortByName).map((meeting) => (
        <Grid item key={meeting.id} className={classes.meeting}>
          <MeetingView
            meeting={meeting}
            onSelect={onSelect}
            isProposal={isProposal}
          />
        </Grid>
      ))}
    </Grid>
  )
}


MeetingsList.propTypes = {
  meetings: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  isProposal: PropTypes.bool.isRequired.isRequired,
}

export default MeetingsList
