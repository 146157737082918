export const meetingsFilterOptions = [
  'Bad Oeynhausen',
  'Espelkamp',
  'Hille',
  'Hüllhorst',
  'Lübbecke',
  'Minden',
  'Petershagen',
  'Porta Westfalica',
  'Preußisch Oldendorf',
  'Rahden',
  'Stemwede',
]
