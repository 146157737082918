import { combineReducers } from 'redux'
import graphReducer from './graphReducer'
import selectedProviderReducer from './selectedProviderReducer'
import appStateReducer from './appStateReducer'
import proposalsReducer from './proposalsReducer'
import confirmationModalReducer from './confirmationModalReducer'

export default combineReducers({
  graph: graphReducer,
  selectedProvider: selectedProviderReducer,
  appState: appStateReducer,
  proposals: proposalsReducer,
  confirmationModal: confirmationModalReducer,
})
