import React from 'react'
import { Fab, Typography, Grid } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { hooks } from 'lq-ui'
import useWindowSize from 'hooks/useWindowSize'
import useStyles from './styles'


const Bubble = ({ bubble }) => {
  const {
    size, icon, label, linkTo, mailTo, disabled,
  } = bubble
  const { isMobile } = useWindowSize()

  const classes = useStyles(size)
  const router = hooks.router.useRouter()

  const onClick = () => {
    if (disabled) {
      return null
    }
    return linkTo ? router.push(linkTo) : window.open(`mailto:${mailTo}`)
  }

  // const onClick = () => (linkTo ? router.push(linkTo) : window.open(`mailto:${mailTo}`))

  return (
    <Fab disableRipple color="primary" className={classes.root} onClick={() => onClick()}>
      <Grid container direction="column" justify="center" alignItems="center">
        <FontAwesomeIcon icon={icon} size={isMobile ? '2x' : '3x'} className={classes.icon} />
        <Typography variant={isMobile ? 'button' : 'subtitle1'} display="block" align="center" className={classes.text}>
          {label}
        </Typography>
      </Grid>
    </Fab>
  )
}

Bubble.propTypes = {
  bubble: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Bubble
