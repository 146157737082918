import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  deleteBtn: {
    backgroundColor: theme.palette.error.dark,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
}))

export default useStyles
