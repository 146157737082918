import React from 'react'
import {
  TextField, Button,
  Dialog, DialogTitle, DialogContent,
  DialogActions,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { fileSelector } from 'store/selectors'
import { uploadFile, updateFile } from 'store/actions'

const DocumentForm = ({ id, onClose }) => {
  const dispatch = useDispatch()
  const file = useSelector(fileSelector(id)) || {}

  const [title, setTitle] = React.useState(file.title)
  const [subtitle, setSubtitle] = React.useState(file.subtitle)

  const handleSubmit = (event) => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const { name } = formData.get('file')

    if (id) {
      formData.append('id', id)
      formData.append('link', file.link)
      formData.append('date', file.date)

      dispatch(name
        ? uploadFile(formData)
        : updateFile({ id, title, subtitle })).then(() => onClose())
    } else {
      dispatch(uploadFile(formData)).then(() => onClose())
    }
  }

  return (

    <Dialog open onClose={onClose}>
      <DialogTitle>{file?.title ?? 'Dokument Hinzufügen'}</DialogTitle>
      <form onSubmit={handleSubmit} name="form">
        <DialogContent>
          <TextField
            name="file"
            variant="outlined"
            inputProps={{ type: 'file', accept: '.pdf, .png, .jpg, .jpeg' }}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Name"
            name="title"
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Kennung"
            name="subtitle"
            variant="outlined"
            value={subtitle}
            onChange={(e) => setSubtitle(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
          >
            Abbrechen
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
          >
            Speichern
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

// #TODO: add validations


DocumentForm.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default DocumentForm
