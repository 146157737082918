import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  main: {
    overflowY: 'auto',
    paddingLeft: theme.spacing.apply(2),
    paddingRight: theme.spacing.apply(2),
  },
}))

export default useStyles
