import React from 'react'
// import ProviderCard from 'components/NewProviders/ProviderCard'
import useNodeInfo from 'hooks/useNodeInfo'
import ProviderCard from 'components/Providers/ProviderCard'
import PropTypes from 'prop-types'
import ProviderCardPrint from 'components/Providers/ProviderCard/ProviderCardPrint'

function NodeProviders({ print }) {
  const [, , providers] = useNodeInfo()

  return (
    <>
      {print
        ? (
          <div>
            {!!providers.length && providers.map((p) => (
              <div
                style={{
                  display: 'inline-block', margin: '0 8px', pageBreakAfter: 'always', width: '100%',
                }}
                key={p.id}
              >
                <ProviderCardPrint provider={p} print={print} />
              </div>
            ))}
          </div>
        )
        : (
          <div style={{ whiteSpace: 'nowrap', overflowX: 'auto' }}>
            {!!providers.length && providers.map((p) => (
              <div
                style={{
                  display: 'inline-block', whiteSpace: 'normal', width: '300px', verticalAlign: 'top', margin: '0 8px',
                }}
                key={p.id}
              >
                <ProviderCard provider={p} />
              </div>
            ))}
          </div>
        )}
    </>
  )
}

NodeProviders.defaultProps = {
  print: false,
}

NodeProviders.propTypes = {
  print: PropTypes.bool,
}

export default NodeProviders
