const graphFormReducer = (state, action) => {
  switch (action.type) {
    case 'NEW_CONDITION': {
      return {
        ...state,
      }
    }
    case 'ADD_NEW_EDGE': {
      return {
        ...state,
        edges: [...state.edges, action.payload],
      }
    }
    case 'UPDATE_EDGE_CONDITIONS': {
      const { edge, value, condition } = action.payload
      const isNewCondition = !Object.keys(edge).includes(condition)

      return {
        ...state,
        edges: state.edges.map((e) => (e.id === edge.id
          ? { ...e, [condition]: value }
          : isNewCondition
            ? { ...e, [condition]: '' }
            : e)),
      }
    }

    case 'REMOVE_EDGE_CONDITION': {
      const { condition, edge } = action.payload
      return {
        ...state,
        edges: state.edges.map((e) => (e.id === edge.id ? { ...e, [condition]: '' } : e)),
      }
    }

    case 'EDIT_CONDITION_VALUE': {
      const { condition, oldValue, newValue } = action.payload
      const updateEdge = (edge) => {
        if (edge[condition] === oldValue) {
          return { ...edge, [condition]: newValue }
        }
        return edge
      }
      return {
        ...state,
        edges: state.edges.map((edge) => updateEdge(edge)),

      }
    }

    case 'REMOVE_CONDITION_VALUE': {
      const { condition, value } = action.payload
      const updateEdge = (edge) => {
        if (edge[condition] === value) {
          return { ...edge, [condition]: '' }
        }
        return edge
      }
      return {
        ...state,
        edges: state.edges.map((edge) => updateEdge(edge)),

      }
    }

    default:
      return state
  }
}

export default graphFormReducer
