import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '15px 10px 10px 10px',
    padding: 10,
    height: '100%',
  },
  shortDescription: {
    width: '100%',
    borderColor: theme.palette.secondary.main,
    border: '3px solid',
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default useStyles
