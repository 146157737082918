import React from 'react'
import { hooks } from 'lq-ui'
import { ReactComponent as RotatePhoneLogo } from 'assets/graphPage/rotatePhone.svg'
import { Typography } from '@material-ui/core'


const MobileRotationHint = () => (
  <div style={{ margin: '10px' }}>
    <Typography component="h5" variant="h5" color="primary">
      {hooks.i18n.useMessage('graph.mobileRotationHint')}
    </Typography>
    <RotatePhoneLogo />
  </div>
)


export default MobileRotationHint
