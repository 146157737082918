import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'


const useStyles = makeStyles(() => ({
  videoResponsive: {
    textAlign: 'center',
    marginBottom: '450px',
    // overflow: 'hidden',
    position: 'relative',
    height: 0,
  },
  iframe: {
    width: '728px',
    height: '410px',
  },
}))
const YoutubeEmbed = ({ embedId }) => {
  const classes = useStyles()
  return (
    <div className={classes.videoResponsive}>
      <iframe
        className={classes.iframe}
        width="728"
        height="410"
        src={embedId}
        title="InTool Tutorial Video"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; "
        allowFullScreen
      />
    </div>
  )
}
YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired,
}
export default YoutubeEmbed
