import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ConditionsList from 'components/GraphEditorComponents/GraphEditor/EdgeConditions/ConditionsList'
import {
  Card, CardActions, Grid, CardContent, Dialog,
  DialogActions, Button, DialogTitle, DialogContent, DialogContentText,
} from '@material-ui/core'


import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { deleteEdge } from 'store/actions'
import styles from './styles'

function EdgeCard({
  edge, destination, onEdit,
}) {
  const classes = styles()

  const [confirmDelete, setConfirmDelete] = React.useState(false)
  const dispatch = useDispatch()

  const onDelete = () => dispatch(deleteEdge(edge))
    .then(() => setConfirmDelete(false))

  return (
    <>
      <Card className={classes.root} variant="outlined">
        <CardContent className={classes.content}>
          <ConditionsList edge={edge} destination={destination} />
        </CardContent>
        <CardActions>
          <Grid container justify="space-between">
            <Grid item>
              <IconButton onClick={onEdit}>
                <EditIcon color="primary" />
              </IconButton>
              <IconButton aria-label="share" onClick={() => setConfirmDelete(true)}>
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          </Grid>

        </CardActions>
      </Card>
      { confirmDelete
        && (
        <Dialog open>
          <DialogTitle>Löschen der Verbindung!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sind Sie sicher, dass Sie diese Verbindung löschen möchten?
              Sie können später jederzeit eine neue Verbindung hinzufügen.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="secondary" variant="contained">
              Abbrechen
            </Button>
            <Button onClick={onDelete} color="primary" variant="contained">
              Bestätigen
            </Button>
          </DialogActions>

        </Dialog>
        )}
    </>
  )
}

EdgeCard.propTypes = {
  edge: PropTypes.object.isRequired,
  destination: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
}

export default EdgeCard
