import React, { useState } from 'react'
import {
  Button, Modal, Heading, Icon, hooks,
} from 'lq-ui'
import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { nodeConnectedGraphSelector, nodeSelector } from 'store/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { deleteNode, selectNode } from 'store/actions'
import styles from './DeleteNodeStyles'

const DeleteNode = () => {
  const classes = styles()
  const [open, setOpen] = useState(false)
  const { nodes, edges } = useSelector(nodeConnectedGraphSelector)
  const nodeId = useSelector(nodeSelector)
  const node = nodes.length ? nodes.find((n) => n.id === nodeId) : {}

  const dispatch = useDispatch()
  const content = {
    header: 'Element Löschen',
    body: `The node ${node.name} will be removed.
    be aware that all connections to the following nodes
    ${nodes.length ? nodes.filter((n) => n.id !== nodeId).map((n) => n.label) : []}
    will be lost.`,
  }
  const handleDeleteNode = () => {
    dispatch(deleteNode(node, edges))
    setOpen(false)
    dispatch(selectNode(0))
  }

  return (
    <>
      { nodeId !== 0 && nodeId !== -1
      && (
      // <Button
      //   className={classes.deleteButton}
      //   onClick={() => setOpen(true)}
      //   variant="fab"
      // >
      //   <Icon iconName="Delete" color="#fff" />
      // </Button>
      <IconButton onClick={() => setOpen(true)}>
        <DeleteIcon color="error" />
      </IconButton>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <div className={classes.modal}>
          <div className={classes.modalHeader}>
            <Heading level="h5" color="#000">
              {content.header}
            </Heading>
          </div>
          <div className={classes.modalContent}>
            <div id="simple-modal-description">
              Sie wollen Knoten
              <b>
                {` ${node.name} `}
              </b>
              löschen.
              bitte nehmen Sie zur Kenntnis,
              dass damit die Verbindungen von
              <b>{` ${node.name} `}</b>
              zu folgenden Knoten ebenfalls gelöscht werden:
              {nodes.length > 0
              && (
              <ul>
                {
                nodes.filter((n) => n.id !== nodeId).map((n) => (
                  <li key={n.id}>
                    <b>{n.label}</b>
                  </li>
                ))
                }
              </ul>
              )}
            </div>
          </div>
          <div className={classes.modalFooter}>
            <Button
              onClick={handleDeleteNode}
              className={classes.confirmButton}
            >
              <Icon iconName="Check" color="#fff" className={classes.buttonIcon} />
              <span style={{ marginLeft: '5px' }}>{hooks.i18n.useMessage('general.confirm')}</span>
            </Button>
            <Button
              onClick={() => setOpen(false)}
              className={classes.cancelButton}
            >
              <Icon iconName="Clear" color="#fff" className={classes.buttonIcon} />
              <span style={{ marginLeft: '5px' }}>{hooks.i18n.useMessage('general.cancel')}</span>
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default DeleteNode
