const _ = require('lodash')


// export function mostRelevantCondition(edges, decisions = {}) {
//   const rates = edges
//     .filter((edge) => Object.entries(edge).every(([k, v]) => !v || !decisions[k] || decisions[k] === v))
//     .reduce((o, e) => Object.entries(e)
//       .filter(([k]) => !['id', 'from', 'to', ...Object.keys(decisions)].includes(k))
//       .reduce((oo, [k, v]) => ({
//         ...oo,
//         [k]: { ...(oo[k] || {}), [(v || '*')]: ((oo[k] || {})[v || '*'] || 0) + 1 },
//       }), o), {})
//   const sorted = Object.entries(rates)
//     .filter(([, v]) => {
//       const values = Object.keys(v)
//       return values.length > 1 || !values.includes('*')
//     })
//     .sort(([, va], [, vb]) => (va['*'] === vb['*'] ? 0 : va['*'] < vb['*'] ? -1 : +1))
//   return !sorted.length || !sorted[0][1]['*'] ? null : sorted[0][0]
// }

export const getConditions = (edge, conditionsValues) => Object.entries(edge)
  .reduce((acc, [key, value]) => (Object.keys(conditionsValues).includes(key) && value !== ''
    ? [...acc, { [key]: value }]
    : acc), [])
  .reduce((acc, i) => (!acc.includes(i) ? [...acc, i] : acc), [])


export const graphSinglePath = (graph, conditions) => {
  const { edges } = graph

  const endNodes = edges.reduce((a, path) => (edges.map((p) => p.from).includes(path.to)
    ? a : [...a, path.to]), [])

  const findPath = (node) => {
    let queue = [edges.find((p) => p.to === node)]
    const getPath = (set) => edges.filter((e) => set.find((r) => e.to === r.from))
    let i = 0

    while (queue.length !== i) {
      i = queue.length
      queue = Array.from(new Set(queue.concat(getPath(queue))))
    }

    return queue
  }

  const getPathConditions = (path) => path.reduce((o, p) => Object.entries(p)
    .reduce((oo, [k, v]) => ((!['id', 'from', 'to'].includes(k) && v !== '') ? ({ ...oo, [k]: v }) : oo), o),
  {})

  const allPaths = endNodes.map((n) => findPath(n)).reduce((a, p) => {
    const pathConditions = getPathConditions(p)
    return _.isEqual(pathConditions, conditions) ? [...a, ...p] : a
  }, [])

  const pathEdges = Object.keys(conditions).length ? Array.from(new Set(allPaths)) : []

  return ({
    edges: Object.keys(conditions).length
      ? pathEdges
      : [],
    nodes: Object.keys(conditions).length
      ? [...graph.nodes.filter((n) => pathEdges.find((e) => e.to === n.id)), ...graph.nodes.filter((n) => n.id === 0)]
      : graph.nodes.filter((n) => n.id === 0),
  })
}


export const getAllConditions = (edges) => {
  const values = edges
    .reduce((o, e) => Object.entries(e)
      .reduce((oo, [k, v]) => (v !== '' && !['id', 'from', 'to', 'arrows'].includes(k) ? {
        ...oo,
        [k]: [...new Set([...(oo[k] || []), v])],
      } : oo), o), {})
  return Object.entries(values)
    .reduce((a, [k, v]) => ({
      ...a,
      [k]: v.includes(true) || v.includes(false)
        ? { name: k, type: 'bool', items: ['Ja', 'Nein'] }
        : { name: k, type: 'select', items: v },
    }), {})
}

export const getEdgeConditions = (edge, conditions) => Object.entries(edge)
  .reduce((acc, [key, value]) => (Object.keys(conditions).includes(key) && value !== ''
    ? [...acc, { [key]: value }]
    : acc), [])
  .reduce((acc, i) => (!acc.includes(i)
    ? [...acc, i] : acc), []).map((c) => Object.keys(c)[0])
