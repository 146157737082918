/* eslint-disable no-param-reassign */
import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import * as vis from 'vis-network'
import { useDispatch, useSelector } from 'react-redux'
import { selectNode } from 'store/actions'
import { getPersona } from 'store/selectors'
import options from './GraphOptions'

const Graph = ({
  nodesData, edgesData, className, id, isEditor, selectedNode,
}) => {
  const refNetworkContainer = useRef()
  const dispatch = useDispatch()
  const persona = useSelector(getPersona)

  const endNodes = React.useMemo(() => edgesData.reduce((a, path) => (edgesData.map((p) => p.from).includes(path.to)
    ? a : [...a, path.to]), []), [edgesData])

  React.useEffect(() => {
    const container = refNetworkContainer.current
    const highlightSelectedNode = (values) => {
      values.shadow = true
      values.shadowSize = 20
      values.shadowColor = '#3A6311'
      values.shadowX = 0
      values.shadowY = 0
      values.size = 10
      values.color = '#ff00ff'
    }

    const nodes = nodesData.map((n) => ({ ...n, chosen: { node: highlightSelectedNode } }))
    const edges = edgesData.map((e) => ({
      ...e,
      arrows: {
        to: {
          enabled: false,
          scaleFactor: 0,
          type: 'arrow',
        },
      },
    }))

    const network = new vis.Network(container, { edges, nodes }, options)

    if (endNodes.length && !selectedNode) {
      network.selectNodes(endNodes, false)
    } else {
      const findPath = (node) => {
        let queue = edges.filter((p) => p.to === node)
        const getPath = (set) => edges.filter((e) => set.find((r) => e.to === r.from))
        let i = 0

        while (queue.length !== i) {
          i = queue.length
          queue = Array.from(new Set(queue.concat(getPath(queue))))
        }

        const path = {
          edges: queue.map((q) => q.id),
          nodes: queue.reduce((a, q) => {
            const edgeNodes = [q.from, q.to]
            return [...a, ...edgeNodes]
          }, []),
        }
        return path
      }

      const newG = { edges: findPath(selectedNode).edges, nodes: Array.from(new Set(findPath(selectedNode).nodes)) }
      network.setSelection({ edges: newG.edges, nodes: newG.nodes }, {
        highlightEdges: false,
      })
    }

    network.on('click', (params) => {
      const node = params.nodes[0]

      dispatch(selectNode(node))
    })
  }, [dispatch, edgesData, endNodes, isEditor, nodesData, persona, selectedNode])

  return <div id={id} ref={refNetworkContainer} className={className} />
}

Graph.defaultProps = {
  className: '',
  highlightedGraph: {
    nodes: [],
    edges: [],
  },
}

Graph.propTypes = {
  id: PropTypes.string.isRequired,
  nodesData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
  edgesData: PropTypes.arrayOf(
    PropTypes.shape({
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired,
    }),
  ).isRequired,
  highlightedGraph: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.number),
    edges: PropTypes.arrayOf(PropTypes.string),
  }),
  className: PropTypes.string,
  isEditor: PropTypes.bool.isRequired,
  selectedNode: PropTypes.number.isRequired,
}

export default Graph
