import React from 'react'
import { useForm, Controller } from 'react-hook-form'
import { hooks } from 'lq-ui'
import { guidGenerator } from 'helpers/guidGenerator'
import PropTypes from 'prop-types'
import ProviderMap from 'components/ProviderMap'
import { updateMeetingInformation, proposeMeeting } from 'store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { entitySelector, proposalSelector } from 'store/selectors'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl,
  Grid,
} from '@material-ui/core'
import { meetingsFilterOptions } from 'consts/meetingsFilter'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useAddress from './useAddress'

const MeetingsForm = ({ id, onClose, isProposal }) => {
  const dispatch = useDispatch()
  const meeting = useSelector(isProposal ? proposalSelector(id) : entitySelector(id, 'meetings'))

  const { user } = useAuth()
  // const classes = styles()
  const defaultValues = {
    name: meeting?.name ?? '',
    email: meeting?.email ?? '',
    phone: meeting?.phone ?? '',
    serviceHours: meeting?.serviceHours ?? '',
    contactName: meeting?.contactName ?? '',
    address: meeting?.address ?? '',
    shortDescription: meeting?.shortDescription ?? '',
    provider: meeting?.provider ?? '',
    district: meeting?.district ?? '',
    map: meeting?.map,
  }

  const proposalSchema = yup.object().shape({
    name: yup.string().required('required field'),
    district: yup.string().required('required field'),
    proposedBy: yup.string().email('must be a valid email').required('required Field'),
  })

  const meetingSchema = yup.object().shape({
    name: yup.string().required('required field'),
    district: yup.string().required('required field'),
  })

  const {
    handleSubmit,
    control,
    // errors,
    // reset,
  } = useForm({
    defaultValues: user
      ? defaultValues : { ...defaultValues, proposedBy: '' },
    resolver: yupResolver(user ? meetingSchema : proposalSchema),
  })


  const address = useAddress(defaultValues.address, defaultValues.map)

  const onSubmit = (data) => {
    dispatch(user
      ? updateMeetingInformation({
        ...data, id: id || guidGenerator(), map: address.map,
      })
      : proposeMeeting({
        ...data,
        id: id || guidGenerator(),
        map: address.map,
        proposedBy: data.proposedBy.trim(),
      })).then(() => onClose())
  }

  const renderTextField = (name, value, onChange, error, rows) => (
    <TextField
      fullWidth
      variant="outlined"
      margin="normal"
      onChange={onChange}
      onBlur={() => (name === 'address' ? address.onBlur(value) : null)}
      error={error}
      value={value}
      helperText={error?.message}
      rows={rows}
      multiline={!!rows}
      label={hooks.i18n.useMessage(`meeting.card.${name}`)}
      name={name}
    />
    // <TextField fullWidth variant="outlined" margin="normal" rows={rows} multiline={!!rows} />
  )

  const renderSelect = (name, value, onChange, error, items) => (
    <FormControl
      variant="outlined"
      fullWidth
      margin="normal"
      error={error}
    >
      <InputLabel id={name}>{hooks.i18n.useMessage(`meeting.card.${name}`)}</InputLabel>
      <Select
        labelId="name"
        value={value}
        onChange={onChange}
        label={hooks.i18n.useMessage(`meeting.card.${name}`)}
      >
        {items.map((i) => <MenuItem value={i} key={i}>{i}</MenuItem>)}
      </Select>
      {error && <FormHelperText error>{error?.message}</FormHelperText>}
    </FormControl>
  )

  return (
    <Dialog open onClose={onClose} maxWidth="md">
      <DialogTitle>
        {
        meeting?.name ?? !user ? 'Sprachgelegenheiten vorschlagen' : 'Neue Sprachgelegenheiten'
        }
      </DialogTitle>
      <form onSubmit={handleSubmit((data) => onSubmit(data))} name="meetingForm">
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="name"
                control={control}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="email"
                control={control}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="phone"
                control={control}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="serviceHours"
                control={control}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="contactName"
                control={control}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Controller
                render={({
                  field: {
                    onChange, value, name,
                  },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="address"
                control={control}
              />
            </Grid>
            <Grid item xs={12}>
              <ProviderMap address={address} editing />
            </Grid>
            <Grid xs={12}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error, 5)
                )}
                name="shortDescription"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderTextField(name, value, onChange, error)
                )}
                name="provider"
                control={control}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  renderSelect(name, value, onChange, error, meetingsFilterOptions)
                )}
                name="district"
                control={control}
              />
            </Grid>
            {!user
              && (
              <Grid item xs={12} sm={6}>
                <Controller
                  render={({
                    field: {
                      onChange, value, name,
                    },
                    fieldState: { error },
                  }) => (
                    renderTextField(name, value, onChange, error)
                  )}
                  name="proposedBy"
                  control={control}
                />
              </Grid>
              )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            color="secondary"
            variant="contained"
          >
            Abbrechen
          </Button>
          <Button
            color="primary"
            type="submit"
            variant="contained"
          >
            Speichern
          </Button>

        </DialogActions>
      </form>
    </Dialog>
  )
}

// #ToDo: Load Graph after a proposal is accepted or rejected


MeetingsForm.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  isProposal: PropTypes.bool.isRequired,
}

export default MeetingsForm
