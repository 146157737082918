import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Option from './Option'
import Tags from './Tags'
import Input from './Input'
import { filterInput, filterNewValue } from './filter'

const AutocompleteChipSelect = ({
  value,
  onChange,
  options,
  label,
  multiple,
  addOptionLabel,
  onDelete,
}) => {
  const [componentValue, setComponentValue] = React.useState(value)
  const [componentOptions, setComponentOptions] = React.useState([])

  return (
    <Autocomplete
      value={componentValue}
      multiple={multiple}
      clearText="Löschen"
      options={[...options, ...componentOptions]}
      freeSolo
      filterOptions={(opt, params) => filterInput(opt, params, `${addOptionLabel}"${params.inputValue}"`)}
      onChange={(_, newValue) => {
        const [suggestion, currentValues] = filterNewValue(newValue, [
          ...options,
          ...componentOptions,
        ])
        if (suggestion) {
          setComponentValue([...currentValues, suggestion.inputValue])
          setComponentOptions([...componentOptions, suggestion.inputValue])
          if (onChange) onChange([...currentValues, suggestion.inputValue])
        } else {
          setComponentValue(
            newValue?.hasOwnProperty('inputValue')
              ? newValue.inputValue
              : newValue,
          )
          setComponentOptions(
            newValue?.hasOwnProperty('inputValue')
              ? [...componentOptions, newValue.inputValue]
              : componentOptions.filter((x) => newValue?.includes(x)),
          )
          if (onChange) {
            onChange(
              newValue?.hasOwnProperty('inputValue')
                ? newValue.inputValue
                : newValue,
            )
          }
        }
      }}
      getOptionLabel={(x) => (multiple ? x : Array.isArray(x) ? x[0] : x)}
      renderOption={(option, state) => (
        <Option option={option} value={state.inputValue} checked={value.includes(option)} />
      )}
      renderTags={(_, getTagProps) => (
        <Tags values={value} getTagProps={getTagProps} onDelete={onDelete} />
      )}
      renderInput={(params) => <Input inputParams={params} label={label} />}
    />
  )
}

AutocompleteChipSelect.defaultProps = {
  multiple: false,
  addOptionLabel: 'Hinzufügen: ',
  value: null,
}

AutocompleteChipSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  addOptionLabel: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
}

export default AutocompleteChipSelect
