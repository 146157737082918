import State from 'store/state'

const _ = require('lodash')


const initialState = {
  selectedNode: 0,
  persona: {},
  conditions: {},
  status: State.initial,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'GRAPH_LOAD_STARTED':
      return {
        ...state,
        status: State.loading,
      }
    case 'GRAPH_LOAD_SUCCESS':
      return {
        ...state,
        status: State.present,
      }
    case 'GRAPH_LOAD_FAILURE':
      return {
        ...state,
        status: State.failure,
      }
    case 'PROPOSALS_LOAD_SUCCESS': {
      return {
        ...state,
        status: State.present,
      }
    }
    case 'GRAPH_UPDATE_STARTED':
      return {
        ...state,
        status: State.loading,
      }
    case 'GRAPH_UPDATE_SUCCESS':
      return {
        ...state,
        status: State.present,
      }
    case 'GRAPH_UPDATE_FAILURE':
      return {
        ...state,
        status: State.failure,
      }
    case 'GRAPH_SELECT':
      return ({
        ...state,
        selectedNode: action.payload === undefined ? state.selectedNode : action.payload,
      })
    case 'UPDATE_CRITERIA':
      return ({
        ...state,
        persona: { ...state.persona, ...action.payload },
      })
    case 'RESET_CRITERIA_VALUES':
      return ({
        ...state,
        persona: {},
        conditions: {},
        selectedNode: 0,
      })
    case 'UPDATE_CONDITIONS':

      return ({
        ...state,
        conditions: Object.values(action.payload)[0] !== ''
          ? { ...state.conditions, ...action.payload }
          : _.omit(state.conditions, Object.keys(action.payload)),
      })
    case 'DELETE_EDGE':
      return {
        ...state,
        status: State.loading,
      }
    case 'DELETE_NODE':
      return {
        ...state,
        status: State.loading,
      }
    case 'UPDATE_EDGE_CONDITIONS':
      return {
        ...state,
        status: State.loading,
      }

    case 'RESET_APP_STATUS':
      return {
        ...state,
        status: State.initial,
      }
    default:
      return state
  }
}
