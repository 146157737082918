import { createSelector } from 'reselect'
import { computeGraphByConditions, computeGraph } from './graph'

export const nodeConnectedGraphSelector = createSelector(
  computeGraph,
  (state) => state.appState.selectedNode,
  (graph, node) => {
    const edges = graph.edges.filter((e) => e.from === node || e.to === node)
    const fromNodes = edges.map((e) => e.from)
    const toNodes = edges.map((e) => e.to)
    const allNodes = [...fromNodes, ...toNodes, node]
      .filter((e, i) => [...fromNodes, ...toNodes].indexOf(e) === i)
    const nodes = graph.nodes
      .filter((n) => allNodes.includes(n.id))
    return ({
      edges,
      nodes,
    })
  },
)

export const nodeSelector = createSelector(
  (state) => state.appState.selectedNode,
  nodeConnectedGraphSelector,
  computeGraphByConditions,
  computeGraph,
  (node, selectedGraph, filterdgraph, graph) => (
    filterdgraph.nodes.map((n) => n.id).includes(node)
    || graph.nodes.map((n) => n.id).includes(node)
    || selectedGraph.nodes.map((n) => n.id).includes(node)
      ? node : 0),
)


export const edgeSelector = (id) => createSelector(
  computeGraph,
  (graph) => {
    const { edges } = graph

    return edges.find((e) => e.id === id)
  },
)

export const edgeConnectedGraphSelector = (edge) => createSelector(
  computeGraph,
  (graph) => {
    const getNode = (id) => {
      const node = graph.nodes.find((n) => n.id === id)
      const edges = graph.edges.filter((e) => e.to !== -1 && [e.from, e.to].includes(node.id))
      const fromNodes = edges.map((e) => e.from)
      const toNodes = edges.map((e) => e.to)
      const nodes = [...fromNodes, ...toNodes]
        .filter((n, i) => [...fromNodes, ...toNodes].indexOf(n) === i && n !== node.id)
      return ({
        node,
        edges,
        nodes,
      })
    }
    return {
      edge,
      fromNode: getNode(edge.from),
      toNode: getNode(edge.to),
    }
  },
)
