import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    conditions: {
      padding: 10,
      marginTop: 5,
    },
    paper: {
      padding: '10px',
      marginTop: '10px',
    },
    edgeHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    conditionsHeader: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px',
      marginTop: '10px',
      flexDirection: 'column',
    },
    button: {
      backgroundColor: '#760074',
      color: '#fff !important',
      marginTop: '10px',
      alignSelf: 'flex-start',
      padding: '4px 6px',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#209BCD',
        color: '#fff',
      },
    },
    heading: {
      overflow: 'inherit',
      whiteSpace: 'inherit',
      wordBreak: 'break-word',
    },
  })
  return useStyles()
}
