import api from 'api'
import { getAllConditions } from 'services/graph'

export const loadGraph = () => async (dispatch) => {
  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const graph = await api.graph.get()
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const updateNodeInformation = (node, nodeEdges) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const updatedNodes = nodes.map((n) => n.id).includes(node.id)
      ? nodes.map((n) => (n.id === node.id ? node : n))
      : [...nodes, node]

    const graph = await api.graph.set({
      nodes: updatedNodes,
      edges: nodeEdges ? [...edges, ...nodeEdges] : edges,
      providers,
      meetings,
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const updateEdgeConditions = (edge) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  const isNewEdge = !edges.find((e) => e.id === edge.id)

  const allConditions = Object.keys(getAllConditions(edges))
  const edgeConditions = Object.keys(edge).filter((k) => !['id', 'from', 'to', 'arrows'].includes(k))
  const newConditions = edgeConditions.filter((c) => !allConditions.includes(c))
  const conditionsToAdd = newConditions.filter((c) => edge[c] || (typeof edge[c] === 'boolean'))
  const conditionsToRemove = newConditions.filter((c) => !edge[c] && !(typeof edge[c] === 'boolean'))

  const remveCon = (e) => Object.entries(e).reduce((a, [k, v]) => {
    if (conditionsToRemove.includes(k)) {
      return a
    }
    return { ...a, [k]: v }
  }, {})

  const updateEdge = (e) => {
    let newEdge = e
    if (conditionsToAdd.length) {
      conditionsToAdd.forEach((c) => {
        newEdge = { ...newEdge, [c]: e.id === edge.id ? edge[c] : '' }
        return newEdge
      })
    }

    return newEdge.id === edge.id ? remveCon(edge) : newEdge
  }

  const updatedEdges = (isNewEdge ? [...edges, edge] : edges).map((e) => remveCon(e)).map((e) => updateEdge(e))


  dispatch({ type: 'UPDATE_EDGE_CONDITIONS', payload: { edge } })
  try {
    const graph = await api.graph.set({
      nodes,
      edges: updatedEdges,
      providers,
      meetings,
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const deleteEdge = (edge) => async (dispatch, getState) => {
  await dispatch({ type: 'DELETE_EDGE', payload: edge })
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  const graph = await api.graph.set({
    nodes,
    edges: edges.filter((e) => (e.id !== edge.id)),
    providers,
    meetings,
    files,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const deleteNode = (node, nondeEdges) => async (dispatch, getState) => {
  await dispatch({ type: 'DELETE_NODE', payload: node })
  const { graph: { nodes, edges, providers } } = getState()
  const graph = await api.graph.set({
    nodes: nodes.filter((n) => (n.id !== node.id)),
    edges: edges.filter((e) => !nondeEdges.map((ne) => ne.id).includes(e.id)),
    providers,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const updateProviderInformation = (provider) => async (dispatch, getState) => {
  await dispatch({ type: 'UPDATE_PROVIDER_INFORMATION', payload: provider })
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()
  const newProviders = provider.id
    ? providers.map((p) => (p.id === provider.id ? provider : p))
    : [...providers, { ...provider, id: providers.length ? Math.max(...providers.map((p) => p.id)) + 1 : 1 }]
  const graph = await api.graph.set({
    nodes,
    edges,
    meetings,
    providers: newProviders,
    files,
  })
  return dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
}

export const deleteProvider = (provider) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const updatedNodes = nodes.map((n) => ({ ...n, providers: n.providers.filter((p) => p !== provider.id) }))

    const graph = await api.graph.set({
      nodes: updatedNodes,
      edges,
      providers: providers.filter((p) => p.id !== provider.id),
      meetings,
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const deleteConditionValue = (condition, value) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const updateEdge = (e) => {
      if (e[condition] === value) {
        return { ...e, [condition]: '' }
      }
      return e
    }

    const graph = await api.graph.set({
      nodes,
      edges: edges.map((e) => updateEdge(e)),
      providers,
      meetings,
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const updateConditionValue = (condition, oldVal, newVal) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  dispatch({ type: 'GRAPH_LOAD_STARTED' })
  try {
    const updateEdge = (e) => {
      if (e[condition] === oldVal) {
        return { ...e, [condition]: newVal }
      }
      return e
    }

    const graph = await api.graph.set({
      nodes,
      edges: edges.map((e) => updateEdge(e)),
      providers,
      meetings,
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}
