import { makeStyles } from '@material-ui/core/styles'
import useWindowSize from 'hooks/useWindowSize'


const useStyles = () => {
  const { isMobile } = useWindowSize()

  const styles = makeStyles((theme) => ({
    paper: {
      // overflowX: 'hidden',
      margin: isMobile ? 0 : theme.spacing(1),
      padding: theme.spacing(isMobile ? 1 : 2),
    },
    button: {
      borderRadius: '4px',
      padding: '6px 8px',
      marginBottom: '.5rem',
    },
    card: {
      marginBottom: '1rem',

    },
    heading: {
      display: 'block',
      color: theme.palette.primary.main,
    },
    impressumContent: {
      align: 'center',
      maxWidth: '80%',
      marginTop: '4rem',
      marginLeft: '2rem',
      whiteSpace: 'pre-line',
    },
  }))

  return styles()
}


export default useStyles
