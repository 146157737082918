import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Heading } from 'lq-ui'
import EdgeForm from 'components/GraphEditorComponents/GraphEditor/EdgeForm'
import { nodeConnectedGraphSelector, conditionsSelector } from 'store/selectors'
import {
  Button, AccordionDetails, Accordion, AccordionSummary, Typography,
} from '@material-ui/core'
import EdgeCard from 'components/GraphEditorComponents/GraphEditor/EdgeCard'
import { useSelector } from 'react-redux'
import GraphFromContextProvider from 'components/GraphEditorComponents/GraphEditor/FormContex/useForm'
import { guidGenerator } from 'helpers/guidGenerator'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getConditions } from 'services/graph'
import ErrorDialog from '../ErrorDialog'
import styles from './ConditionsListStyles'


const EdgesList = ({ node, destination }) => {
  const classes = styles()
  const [edge, setEdge] = useState(null)
  const conditionsValues = useSelector(conditionsSelector)

  const edgeConditions = (e) => getConditions(e, conditionsValues)
    .sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]))

  const edges = useSelector(nodeConnectedGraphSelector).edges
    .filter((e) => e[destination] === node)

  const { appState: { status } } = useSelector((state) => state)
  const { graph } = useSelector((state) => state)


  const getNode = (id) => graph.nodes.find((n) => n.id === parseInt(id, 10))

  const groupEdges = edges.reduce((o, e) => (
    {
      ...o,
      [getNode(e[destination === 'from' ? 'to' : 'from']).label]: {
        ...o[getNode(e[destination === 'from' ? 'to' : 'from']).label],
        [e.id]: e,
      },
    }),
  {})


  const sortEdges = (a, b) => {
    const aFirstCondition = edgeConditions(a)
      .sort((x, y) => Object.keys(x)[0].localeCompare(Object.keys(y)[0]))[0]
    const bFirstCondition = edgeConditions(b)
      .sort((x, y) => Object.keys(x)[0].localeCompare(Object.keys(y)[0]))[0]

    return Object.keys(aFirstCondition)[0].localeCompare(Object.keys(bFirstCondition)[0])
  }

  const connectionsByNode = (nodeEdges) => {
    const edgesWithNoConditions = nodeEdges.filter((e) => !edgeConditions(e).length)
    const edgesWithConditions = nodeEdges.filter((e) => edgeConditions(e).length)
      .sort(sortEdges)

    return [...edgesWithNoConditions, ...edgesWithConditions]
  }
  return (
    <div>
      {(node !== 0 || destination !== 'to')
      && (
      <div style={{ marginBottom: '1rem' }}>
        <Heading level="h6" color="#3A6311" className={classes.heading}>
          {destination === 'to' ? 'Eingangsverbindung' : 'Ausgangsverbindung'}
        </Heading>
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setEdge({ id: guidGenerator() })}
        >
          {destination === 'to' ? 'Neue Eingangsverbindung' : 'Neue Ausgangsverbindung'}
        </Button>
      </div>
      )}
      {Object.keys(groupEdges).sort().map((g) => (
        <Accordion key={g}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>{g}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ display: 'block' }}>
            {connectionsByNode(Object.values(groupEdges[g])).map((e) => (
              <EdgeCard
                key={e.id}
                destination={destination}
                edge={e}
                onEdit={() => setEdge(e)}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      ))}


      {edge && (
      <GraphFromContextProvider initialState={graph}>
        <EdgeForm node={node} destination={destination} onClose={() => setEdge(false)} edgeId={edge.id} />
      </GraphFromContextProvider>
      )}
      { status === 5
        && <ErrorDialog />}
    </div>

  )
}

EdgesList.propTypes = {
  node: PropTypes.number.isRequired,
  destination: PropTypes.string.isRequired,
}

export default EdgesList


// # TODO
// change edge conditions to node edge
// change new edge to edge form
