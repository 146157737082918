import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    marginRight: theme.spacing(2),
    maxWidth: theme.spacing(8),
  },
  logo: {
    maxWidth: '100%',
  },
  title: {
    flexGrow: 1,
  },
  signoutButton: {
    marginLeft: theme.spacing(1),
  },
}))

export default useStyles
