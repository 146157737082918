import React, { useState } from 'react'
import GraphConfiguration from 'components/GraphView/GraphConfiguration'
import MobileRotationHint from 'components/GraphView/MobileRotationHint'
import Layout from '../layouts/General'

const GraphPage = () => {
  const isMobile = (
    (window.innerWidth <= 414 && window.innerHeight <= 823) || (window.innerWidth <= 823 && window.innerHeight <= 414))
  const isLandscapeMode = window.orientation === 90 || window.orientation === -90

  const [graphPageContent, setGraphPageContent] = useState(isMobile && !isLandscapeMode
    ? <MobileRotationHint />
    : <GraphConfiguration />)


  React.useEffect(() => {
    window.addEventListener('orientationchange', () => {
      if (!isLandscapeMode) {
        setGraphPageContent(<GraphConfiguration />)
      } else if (isLandscapeMode && isMobile) {
        setGraphPageContent(<MobileRotationHint />)
      }
    })
  }, [isLandscapeMode, isMobile])

  return (
    <Layout title="InTool für Sprachkurse">
      {graphPageContent}
    </Layout>
  )
}

export default GraphPage
