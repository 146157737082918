export const selectNode = (node) => ({
  type: 'GRAPH_SELECT',
  payload: node,
})

export const updateCriteria = (criteria) => ({
  type: 'UPDATE_CRITERIA',
  payload: criteria,
})

export const updateConditions = (condition) => ({
  type: 'UPDATE_CONDITIONS',
  payload: condition,
})

export const updateCriteriaValues = (criteriaValues) => ({
  type: 'UPDATE_CRITERIA_VALUES',
  payload: criteriaValues,
})

export const resetCriteriaValues = () => ({
  type: 'RESET_CRITERIA_VALUES',
})

export const selectProvider = (provider) => ({
  type: 'SELECT_PROVIDER',
  payload: provider,
})

export const resetAppStatus = () => async (dispatch) => {
  dispatch({ type: 'RESET_APP_STATUS' })
}

export const closeConfirmationModal = () => async (dispatch) => {
  dispatch({ type: 'CLOSE_CONFIRMATION_MODAL' })
}
