import React from 'react'
import PropTypes from 'prop-types'
import { Card } from 'lq-ui'
import AddressPreview from 'components/AddressPreview'
import styles from './ProviderMapStyles'

const ProviderMap = ({
  editing, address,
}) => {
  const classes = styles()
  const mapClickHandler = (value) => (value !== ''
    ? window.open(`https://www.google.com/maps/place/${address.value}`)
    : null)
  const { value, map, currentValue } = address

  return editing ? (
    <AddressPreview
      source={map}
      changed={value !== currentValue}
    />
  ) : (
    <Card.Media
      className={classes.media}
      image={map}
      title={value}
      clickHandler={mapClickHandler}
    />
  )
}

ProviderMap.defaultProps = {
  editing: false,
}

ProviderMap.propTypes = {
  editing: PropTypes.bool,
  address: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.func, PropTypes.object],
  ).isRequired,
}

export default ProviderMap
