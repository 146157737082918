import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardHeader, CardActions, Typography, Grid, CardContent, Box,
} from '@material-ui/core'
// import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import NodeForm from 'components/GraphEditorComponents/GraphEditor/NodeForm'

import styles from './styles'

const NodeCard = ({ node = {} }) => {
  const [open, setOpen] = React.useState(false)
  const classes = styles()


  return (
    <>
      <Card className={classes.container}>
        <CardHeader title={node.label} />
        <CardContent>
          {node.shortDescription
          && (
          <Box className={classes.shortDescription}>
            <Typography color="primary" variant="subtitle2">
              {node.shortDescription}
            </Typography>
          </Box>
          )}
          {node.description
          && (
          <Typography>
            {node.description}
          </Typography>
          )}
        </CardContent>
        { node.id !== 0
        && (
        <CardActions>
          <Grid container justify="space-between">
            <Grid item>
              <IconButton onClick={() => setOpen(true)}>
                <EditIcon color="primary" />
              </IconButton>
              {/* <IconButton aria-label="share" onClick={() => console.log('no')}>
                <DeleteIcon color="error" />
              </IconButton> */}
            </Grid>
          </Grid>

        </CardActions>
        )}
      </Card>
      {open && <NodeForm node={node} onClose={() => setOpen(false)} />}
    </>
  )
}

NodeCard.defaultProps = {
  node: {},
}

NodeCard.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    hours: PropTypes.string,
    duration: PropTypes.string,
    group: PropTypes.string,
  }),
}

export default NodeCard
