import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardHeader, Typography, Grid, CardContent,
} from '@material-ui/core'

import styles from './ProviderCardStyles'


const ProviderCard = ({
  provider,
}) => {
  const classes = styles()


  return (
    <Grid item xs={12} className={classes.root}>
      <Card>
        <CardHeader title={provider.name} />
        {provider.shortDescription
          && (
          <CardContent className={classes.shortDescription}>
            <Typography variant="body2" color="textSecondary" component="p">
              {provider.shortDescription}
            </Typography>
          </CardContent>
          )}
        <CardContent style={{ textAlign: 'center' }}>
          <img src={provider.map} alt={provider.address} style={{ maxWidth: '100%', width: '300px' }} />
        </CardContent>
        <CardContent>
          {provider.email
          && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Email:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              {provider.email}
            </Typography>
          </Grid>
          )}
          {provider.phone && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Telefon:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              {provider.phone}
            </Typography>
          </Grid>
          )}
          {provider.url && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Website:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              {provider.url}
            </Typography>
          </Grid>
          )}
          {provider.address && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Address:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              {provider.address}
            </Typography>
          </Grid>
          )}
        </CardContent>
        {provider.description
        && (
        <CardContent>
          <Typography paragraph>
            {provider.description}
          </Typography>
        </CardContent>
        )}
      </Card>
    </Grid>
  )
}


ProviderCard.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
}

export default ProviderCard
