import React from 'react'
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { updateEdgeConditions } from 'store/actions'


const ConfirmEdgeChanges = ({ data, onClose, formClose }) => {
  const dispatch = useDispatch()
  const onConfirm = () => dispatch(updateEdgeConditions(data))
    .then(() => {
      formClose()
      onClose()
    })


  return (
    <Dialog open>
      <DialogTitle>
        Änderungen speichern
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Wollen Sie Ihre Änderungen wirklich übernehmen?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="contained">
          Abbrechen
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
        >
          Bestätigen
        </Button>
      </DialogActions>

    </Dialog>

  )
}

ConfirmEdgeChanges.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  formClose: PropTypes.func.isRequired,

}


export default ConfirmEdgeChanges
