import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '.5rem',
      margin: '1rem',
    },
    heading: {
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
    deleteButton: {
      margin: '2rem 0 2rem 3rem',
      backgroundColor: '#B00020',
      color: '#fff',
      alignSelf: 'flex-start',
      '&:hover': {
        backgroundColor: '#B00020',
      },
    },
  })
  return useStyles()
}
