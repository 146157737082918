import React, { useReducer, useCallback, createContext } from 'react'
import PropTypes from 'prop-types'
// import { useSelector } from 'react-redux'
import graphFormReducer from './graphFormReducer'

const GraphFromContext = createContext(undefined)


export const useGraphForm = () => React.useContext(GraphFromContext)

const GraphFromContextProvider = ({ children, initialState }) => {
  const [state, dispatch] = useReducer(graphFormReducer, initialState)

  const addNewEdge = useCallback(
    (payload) => dispatch({ type: 'ADD_NEW_EDGE', payload }),
    [],
  )
  const updateEdgeCondition = useCallback(
    (payload) => dispatch({ type: 'UPDATE_EDGE_CONDITIONS', payload }),
    [],
  )

  const removeEdgeCondition = useCallback(
    (payload) => dispatch({ type: 'REMOVE_EDGE_CONDITION', payload }),
    [],
  )

  const editConditionValue = useCallback(
    (payload) => dispatch({ type: 'EDIT_CONDITION_VALUE', payload }),
    [],
  )

  const removeConditionValue = useCallback(
    (payload) => dispatch({ type: 'REMOVE_CONDITION_VALUE', payload }),
    [],
  )

  const actions = {
    updateEdgeCondition, removeEdgeCondition, editConditionValue, removeConditionValue, addNewEdge,
  }

  GraphFromContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
    initialState: PropTypes.object.isRequired,
  }
  return (
    <GraphFromContext.Provider value={{ state, actions }}>
      {children}
    </GraphFromContext.Provider>
  )
}


export default GraphFromContextProvider


// export const updateConditionValue = (condition, oldVal, newVal) => async (dispatch, getState) => {
//   const {
//     graph: {
//       nodes, edges, providers, meetings, files,
//     },
//   } = getState()

//   dispatch({ type: 'GRAPH_LOAD_STARTED' })
//   try {
//     const updateEdge = (e) => {
//       if (e[condition] === oldVal) {
//         return { ...e, [condition]: newVal }
//       }
//       return e
//     }

//     const graph = await api.graph.set({
//       nodes,
//       edges: edges.map((e) => updateEdge(e)),
//       providers,
//       meetings,
//       files,
//     })
//     dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
//   } catch (error) {
//     dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
//   }
// }
