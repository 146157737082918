import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { conditionsSelector } from 'store/selectors'
import { getConditions } from 'services/graph'
import { Typography } from '@material-ui/core'
import styles from './styles'
import SetConditions from './SetConditions'

const ConditionsList = ({ edge }) => {
  const conditionsValues = useSelector(conditionsSelector)
  const classes = styles()
  const conditions = getConditions(edge, conditionsValues)
    .sort((a, b) => Object.keys(a)[0].localeCompare(Object.keys(b)[0]))

  return (conditions.length
    ? <SetConditions edgeConditions={conditions} edge={edge} className={classes.paper} />
    : (
      <Typography component="h6" variant="subtitle2">
        Keine Voraussetzungen vorhanden. Klicken Sie auf Bearbeiten (Show Bleistift Icon), um Werte anzulegen.
      </Typography>
    )
  )
}

ConditionsList.propTypes = {
  edge: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ConditionsList
