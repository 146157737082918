/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import PropType from 'prop-types'
import TextField from '@material-ui/core/TextField'

const Input = ({ inputParams, label }) => (
  <TextField {...inputParams} variant="outlined" multiline label={label} />
)

Input.propTypes = {
  label: PropType.string.isRequired,
  inputParams: PropType.objectOf(PropType.any).isRequired,
}

export default Input
