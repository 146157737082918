import { makeStyles } from '@material-ui/core/styles'

const useStyles = (isMobile) => {
  const styles = makeStyles((theme) => ({
    root: {
      marginTop: 'auto',
      borderTopStyle: 'solid',
      borderTopWidth: theme.spacing(0.75),
      borderBlockColor: theme.palette.secondary.main,
    },
    container: {
      padding: theme.spacing(1, 1),
    },
    logoItem: {
      height: theme.spacing(isMobile ? 4 : 8),
    },
    image: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    bg: {
      backgroundImage: 'url(assets/jobcenterBg.jpeg)',
      height: isMobile ? '100px' : '200px',
      opacity: '0.7',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    linksContainer: {
      textAlign: 'end',
    },
    link: {
      fontWeight: theme.typography.fontWeightBold,
      marginRight: theme.spacing(1),
    },
  }))

  return styles()
}


export default useStyles
