import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent'
import { nodeSelector, nodeConnectedGraphSelector, computeGraphByConditions } from 'store/selectors'
import Graph from './Graph'

const GraphContainer = ({
  className, isEditor, id, showLoading, onSelect,
}) => {
  const { appState: { status, persona } } = useSelector((state) => state)
  const selectedNode = useSelector(nodeSelector)
  const graph = useSelector(computeGraphByConditions)
  const editorGraph = useSelector(nodeConnectedGraphSelector)

  return (
    <>
      { status === 2
        ? (
          <Graph
            nodesData={isEditor ? editorGraph.nodes : graph.nodes}
            edgesData={isEditor ? editorGraph.edges : graph.edges}
            className={className}
            criteria={persona}
            id={id}
            selectedNode={selectedNode}
            isEditor
            onSelect={onSelect}
          />
        )
        : (showLoading && <LoadingSpinnerView />)}
    </>
  )
}

GraphContainer.defaultProps = {
  className: '',
  isEditor: false,
  id: 'network',
  showLoading: true,
  onSelect: () => null,
}

GraphContainer.propTypes = {
  className: PropTypes.string,
  isEditor: PropTypes.bool,
  id: PropTypes.string,
  showLoading: PropTypes.bool,
  onSelect: PropTypes.func,
}

export default GraphContainer
