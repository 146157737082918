import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    container: {
      height: '100%',
    },
    graphContainer: {
      height: '100%',
    },
    legends: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'space-between',
    },
    legendIcon: {
      padding: '5px',
    },
    graph: {
      height: '100%',
    },
    sideNavContainer: {
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
      height: '100%',
      overflowY: 'auto',
    },
  })
  const classes = useStyles()
  return classes
}
