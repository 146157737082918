import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    container: {
      margin: '15px 10px 10px 10px',
      padding: 10,
      height: '100%',
    },
  })
  return useStyles()
}
