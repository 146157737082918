import { createSelector } from 'reselect'
import { filterNodeByExclusionCriteria } from 'helpers/filterNodeByExclusionCriteria'
// import { getAllConditions } from 'services/graph'

const _ = require('lodash')


export const computeGraph = createSelector(
  (state) => state.graph.nodes.filter((n) => n.id !== -1),
  (state) => state.graph.edges.filter((e) => e.from !== -1 && e.to !== -1),
  (state) => state.appState.conditions,
  (nodes, edges, conditions) => {
    const checkPersona = (edge) => Object.entries(conditions)
      .reduce((p, [key, value]) => p && (value === '' || !edge[key] || edge[key] === value), true)

    const outbound = (set) => edges.filter((e) => set.find((r) => e.from === r.to && checkPersona(e)))
    let count = 0
    let reachable = edges.filter((e) => !e.from && checkPersona(e))
    while (reachable.length !== count) {
      count = reachable.length
      reachable = Array.from(new Set(reachable.concat(outbound(reachable))))
    }
    return ({
      edges: reachable.filter((e) => e.to).map((edge) => ({ ...edge })),
      nodes: nodes.filter((n) => reachable.find((e) => e.to === n.id || e.from === n.id)),
    })
  },
)

export const computeGraphByConditions = createSelector(
  computeGraph,
  (state) => state.appState.conditions,
  (graph, conditions) => {
    const checkEdge = (e) => Object.entries(e)
      .reduce((p, [k, v]) => p
      && (['id', 'from', 'to'].includes(k) || v === '' || conditions[k] === v), true)

    const inbound = (set) => graph.edges.filter((e) => set.find((r) => e.from === r.to && checkEdge(e)))
    let count = 0
    let paths = graph.edges.filter((e) => !e.from && checkEdge(e))
    while (paths.length !== count) {
      count = paths.length
      paths = Array.from(new Set(paths.concat(inbound(paths))))
    }

    const edges = paths
    const nodes = graph.nodes
      .filter((n) => edges.find((e) => e.to === n.id))
      // .filter((n) => filterNodeByExclusionCriteria(n, conditions))

    const validNodes = nodes.filter((n) => filterNodeByExclusionCriteria(n, conditions))
    const excludedNodes = nodes.filter((n) => !filterNodeByExclusionCriteria(n, conditions)).map((n) => n.id)

    const validEdges = edges.filter((e) => !(excludedNodes.includes(e.from) || excludedNodes.includes(e.to)))

    return ({
      edges: Object.keys(conditions).length
        ? validEdges
        : [],
      nodes: Object.keys(conditions).length
        ? [...validNodes, ...graph.nodes.filter((n) => n.id === 0)]
        : graph.nodes.filter((n) => n.id === 0),
    })
  },
)


export const onePathGraph = createSelector(
  computeGraphByConditions,
  (state) => state.appState.conditions,
  (graph, conditions) => {
    const { edges } = graph

    const endNodes = edges.reduce((a, path) => (edges.map((p) => p.from).includes(path.to)
      ? a : [...a, path.to]), [])

    const findPath = (node) => {
      let queue = [edges.find((p) => p.to === node)]
      const getPath = (set) => edges.filter((e) => set.find((r) => e.to === r.from))
      let i = 0

      while (queue.length !== i) {
        i = queue.length
        queue = Array.from(new Set(queue.concat(getPath(queue))))
      }

      return queue
    }

    const getPathConditions = (path) => path.reduce((o, p) => Object.entries(p)
      .reduce((oo, [k, v]) => ((!['id', 'from', 'to'].includes(k) && v !== '') ? ({ ...oo, [k]: v }) : oo), o),
    {})

    const allPaths = endNodes.map((n) => findPath(n)).reduce((a, p) => {
      const pathConditions = getPathConditions(p)
      return _.isEqual(pathConditions, conditions) ? [...a, ...p] : a
    }, [])

    const pathEdges = Object.keys(conditions).length ? Array.from(new Set(allPaths)) : []

    return ({
      edges: Object.keys(conditions).length
        ? pathEdges
        : [],
      nodes: Object.keys(conditions).length
        ? [...graph.nodes.filter((n) => pathEdges.find((e) => e.to === n.id)),
          ...graph.nodes.filter((n) => n.id === 0)]
        : graph.nodes.filter((n) => n.id === 0),
    })
  },
)

export const nodeInfoSelector = createSelector(
  (state) => state.graph.nodes,
  (state) => state.appState.selectedNode,
  (nodes, id) => nodes.find((node) => node.id === id),
)

export const selectedEdge = (edge) => createSelector(
  (state) => state.graph.edges.find((e) => e.id === edge.id) || edge,
  (updatedEdge) => updatedEdge,
)


export const fileSelector = (fileId) => createSelector(
  (state) => state.graph.files.find((file) => file.id === fileId),
  (file) => file,
)

export const entitySelector = (id, type) => createSelector(
  (state) => state.graph[type].find((item) => item.id === id),
  (entity) => entity,
)

export const proposalSelector = (id) => createSelector(
  (state) => state.proposals.find((item) => item.id === id),
  (item) => item,
)


// export const computeGraph = createSelector(
//   (state) => state.graph.nodes.filter((n) => n.id !== -1),
//   (state) => state.graph.edges.filter((e) => e.from !== -1 && e.to !== -1),
//   (state) => state.appState.conditions,
//   (nodes, edges, conditions) => {
//     const checkPersona = (edge) => Object.entries(conditions)
//       .reduce((p, [key, value]) => p && (value === '' || !edge[key] || edge[key] === value), true)

//     const outbound = (set) => edges.filter((e) => set.find((r) => e.from === r.to && checkPersona(e)))
//     let count = 0
//     let reachable = edges.filter((e) => !e.from && checkPersona(e))
//     while (reachable.length !== count) {
//       count = reachable.length
//       reachable = Array.from(new Set(reachable.concat(outbound(reachable))))
//     }
//     return ({
//       edges: reachable.filter((e) => e.to).map((edge) => ({ ...edge })),
//       nodes: nodes.filter((n) => reachable.find((e) => e.to === n.id || e.from === n.id)),
//     })
//   },
// )


// const findAllPaths = t => {
//   const { edges } = graph;
//   const inbound = set => edges.filter(e => set.find(r => e.from === r.to));
//   let reachable = edges.filter(e => e.to === t);
//   let count = 0;
//   while (reachable.length !== count) {
//     count = reachable.length;
//     reachable = Array.from(new Set(reachable.concat(inbound(reachable))));
//   }

//   console.log(reachable);
// };


export const conditionsToNodeSelector = createSelector(
  computeGraph,
  (state) => state.appState.selectedNode,
  ({ edges }, node) => {
    const inbound = (set) => edges.filter((e) => set.find((r) => e.from === r.to))
    let reachable = edges.filter((e) => e.to === node)
    let count = 0
    while (reachable.length !== count) {
      count = reachable.length
      reachable = Array.from(new Set(reachable.concat(inbound(reachable))))
    }
    const conditions = reachable.reduce((o, p) => Object.entries(p)
      .reduce((oo, [k, v]) => ((!['id', 'from', 'to', 'arrows'].includes(k) && v !== '')
        ? oo[k]
          ? oo[k].includes(v)
            ? oo
            : ({ ...oo, [k]: [...oo[k], v] })
          : ({ ...oo, [k]: [v] })
        : oo), o),
    {})

    return conditions
  },
)
