import React from 'react'
import PropTypes from 'prop-types'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Option from './Option'
import Tags from './Tags'
import Input from './Input'
import { filterInput, filterNewValue } from './filter'

const ConditionValueSelect = ({
  value,
  onChange,
  options,
  label,
  multiple,
  addOptionLabel,
  onEdit,
  onDelete,
}) => {
  const [componentValue, setComponentValue] = React.useState(value)

  return (
    <Autocomplete
      value={componentValue}
      multiple={multiple}
      clearText="Löschen"
      options={options}
      freeSolo
      filterOptions={(opt, params) => filterInput(opt, params, `${addOptionLabel}"${params.inputValue}"`)}
      onChange={(_, newValue) => {
        const [suggestion, currentValues] = filterNewValue(newValue, options)
        if (suggestion) {
          setComponentValue([...currentValues, suggestion.inputValue])
          if (onChange) onChange([...currentValues, suggestion.inputValue])
        } else {
          setComponentValue(
            newValue?.hasOwnProperty('inputValue')
              ? newValue.inputValue
              : newValue,
          )
          if (onChange) {
            onChange(
              newValue?.hasOwnProperty('inputValue')
                ? newValue.inputValue
                : newValue,
            )
          }
        }
      }}
      getOptionLabel={(x) => (multiple ? x : Array.isArray(x) ? x[0] : x)}
      renderOption={(option, state) => (
        <Option
          option={option}
          onDelete={onDelete}
          onEdit={onEdit}
          state={state}
        />
      )}
      renderTags={(v, getTagProps) => (
        <Tags values={v} getTagProps={getTagProps} />
      )}
      renderInput={(params) => <Input inputParams={params} label={label} />}
    />
  )
}

ConditionValueSelect.defaultProps = {
  multiple: false,
  addOptionLabel: 'Hinzufügen: ',
  value: null,
}

ConditionValueSelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  addOptionLabel: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default ConditionValueSelect
