import React from 'react'
// import { Heading, Card, hooks } from 'lq-ui'

import NodeInfoBox from 'components/GraphView/NodeInfoBox'
import useNodeInfo from 'hooks/useNodeInfo'
import { Typography, Box } from '@material-ui/core'

const NodeInfoView = () => {
  const [node] = useNodeInfo()

  return (
    <Box>
      <Typography variant="h6" color="primary">
        {node.name}
      </Typography>
      <NodeInfoBox />
      <Typography>
        {node.description}
      </Typography>
    </Box>
  )
}

export default NodeInfoView
