import React, { lazy, Suspense } from 'react'
import {
  Icon, Tabs, Heading, Paper,
} from 'lq-ui'
import useNodeInfo from 'hooks/useNodeInfo'
import { useSelector } from 'react-redux'
import NodeEdges from 'components/GraphEditorComponents/GraphEditor/NodeEdges'
import NodeSelect from 'components/GraphEditorComponents/GraphEditor/NodeSelect'
import DeleteNode from 'components/GraphEditorComponents/GraphEditor/DeleteNode'
import NodeCard from 'components/GraphEditorComponents/GraphEditor/NodeCard'
// import NodeProviders from 'components/GraphEditorComponents/GraphEditor/NodeProviders'
import { Button, IconButton } from '@material-ui/core'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import NodeForm from 'components/GraphEditorComponents/GraphEditor/NodeForm'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent/LoadingSpinnerView'
import TransparencyTool from 'components/GraphEditorComponents/GraphEditor/TransparencyTool'
import styles from './EditorPanelStyles'

const EditorPanel = () => {
  const [value, setValue] = React.useState(0)
  const [newNode, setNewNode] = React.useState(null)
  const handleChange = (_, newValue) => setValue(newValue)
  const [node, , nodeProviders] = useNodeInfo()
  const { graph: { providers } } = useSelector((state) => state)
  const classes = styles()
  const NodeProviders = lazy(() => import('components/GraphEditorComponents/GraphEditor/NodeProviders'))
  const [open, setOpen] = React.useState(false)

  return (
    <div>
      <NodeSelect />
      <Paper className={classes.header}>
        <Button
          onClick={() => setNewNode({})}
          color="primary"
          variant="contained"
          fullWidth
        >
          Element Hinzufügen
        </Button>
      </Paper>
      {newNode && <NodeForm node={{}} onClose={() => setNewNode(null)} isNewNode />}
      { node
      && (
      <Paper className={classes.header}>
        <Heading level="h5" color="#3A6311" className={classes.heading}>
          {node && node.name}
        </Heading>
        <DeleteNode />
        { node.id !== 0 && node.id !== -1
        && (
        <IconButton onClick={() => setOpen(true)}>
          <InfoOutlinedIcon color="info" />
        </IconButton>
        )}
      </Paper>
      )}
      <Tabs
        activeKey={value}
        onSelect={handleChange}
        tabButtons={[
          { label: 'Voraussetzungen', icon: <Icon color={value === 0 ? '#3A6311' : '#757575'} iconName="Tune" /> },
          {
            label: 'Informationen',
            icon: <Icon color={value === 1 ? '#3A6311' : '#757575'} iconName="Description" />,
          },
          { label: 'Anbieter', icon: <Icon color={value === 2 ? '#3A6311' : '#757575'} iconName="Apartment" /> },
        ]}
      >
        <Tabs.Content value={value} index={0}>
          <NodeEdges />
        </Tabs.Content>
        <Tabs.Content value={value} index={1}>
          <NodeCard node={node} />
        </Tabs.Content>
        <Tabs.Content value={value} index={2}>
          <Suspense fallback={<LoadingSpinnerView />}>
            <NodeProviders
              node={node}
              nodeProviders={nodeProviders}
              providers={providers}
            />
          </Suspense>
        </Tabs.Content>
      </Tabs>
      {open && <TransparencyTool onClose={() => setOpen(false)} />}
    </div>
  )
}


export default EditorPanel
