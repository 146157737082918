import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
}))
export default useStyles
