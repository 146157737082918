import React from 'react'
import DocumentCard from 'components/Documents/DocumentCard'
import { useSelector } from 'react-redux'
import LoadingSpinnerView from 'components/LoadingSpinnerComponent'
import { Grid, Button } from '@material-ui/core'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import styles from './DocumentsStyles'
import DocumentForm from './DocumentForm'

const Documents = () => {
  const classes = styles()
  const { graph: { files }, appState: { status } } = useSelector((state) => state)
  const [selectedFile, setSelectedFile] = React.useState(null)
  const { user } = useAuth()

  const sortByDate = (a, b) => {
    if (new Date(a.date) > new Date(b.date)) {
      return -1
    } if (new Date(a.date) < new Date(b.date)) {
      return 1
    }
    return 0
  }

  return (
    <>
      {status === 2
        ? (
          <Grid container spacing={2} className={classes.root} alignItems="stretch">
            {user
            && (
            <Grid item className={classes.document}>
              <Button
                variant="outlined"
                color="primary"
                className={classes.addNewBtn}
                onClick={() => setSelectedFile({})}
              >
                Dokument hinzufügen
              </Button>
            </Grid>
            )}
            {files.sort(sortByDate).map((file) => (
              <Grid item key={file.id} className={classes.document}>
                <DocumentCard file={file} onSelect={(value) => setSelectedFile(value)} />
              </Grid>
            ))}
          </Grid>
        )
        : <LoadingSpinnerView />}
      {selectedFile && <DocumentForm id={selectedFile.id || ''} onClose={() => setSelectedFile(null)} />}
    </>

  )
}

export default Documents
