import React from 'react'
import styles from 'components/GraphView/NodeInfoBox/NodeInfoBoxStyles'
import useNodeInfo from 'hooks/useNodeInfo'
import { Typography, Box } from '@material-ui/core'

const NodeInfoBox = () => {
  const [node] = useNodeInfo()
  const classes = styles()
  return node ? (

    <Box className={classes.container}>
      <Typography color="primary" variant="subtitle2">
        {node.shortDescription}
      </Typography>
    </Box>

  ) : ''
}

export default NodeInfoBox
