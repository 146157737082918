import React from 'react'
import PropTypes from 'prop-types'
import {
  List, ListItem, ListItemText, Divider,
} from '@material-ui/core'
import { hooks } from 'lq-ui'
import { formParams, resolveParams } from 'helpers/urlParams'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import { useLocation } from 'react-router-dom'

const MeetingsFilter = ({ filterItems, addNewMeeting }) => {
  const router = hooks.router.useRouter()
  const { match: { path } } = router
  const fullUrl = useLocation()
  const currentPath = path.substring(1)

  const setRoute = (item) => (['proposals', 'meetings'].includes(item) ? `/${item}` : `${path}/${formParams(item)}`)
  const district = resolveParams(fullUrl.pathname.substring(path.length + 1))

  const { user } = useAuth()

  return (
    <div>
      <List>
        {['Alle', ...filterItems.sort()].map((text) => (
          <ListItem
            selected={district ? district === text.toLowerCase() : text === 'Alle'}
            button
            key={text}
            onClick={() => (text === 'Alle'
              ? router.push(path)
              : router.push(setRoute(text)))}
          >
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {user && (
        <ListItem
          button
          onClick={() => (
            router.push(currentPath === 'meetings' ? '/proposals' : '/meetings'))}
        >
          <ListItemText primary={currentPath === 'meetings' ? 'Vorschläge' : 'Sprachgelegenheiten'} />
        </ListItem>
        )}
        <ListItem button>
          <ListItemText
            primary={user ? 'Sprachgelegenheiten Hinzufügen' : 'Sprachgelegenheiten vorschlagen'}
            onClick={addNewMeeting}
          />
        </ListItem>

      </List>
    </div>

  )
}

MeetingsFilter.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  addNewMeeting: PropTypes.func.isRequired,
}

export default MeetingsFilter
