
import { makeStyles } from '@material-ui/core'
import useWindowSize from 'hooks/useWindowSize'


export default () => {
  const { isMobile } = useWindowSize()

  const useStyles = makeStyles((theme) => ({
    root: {
      height: '100%',
    },
    menu: {
      borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      height: '100%',
    },
    mobileMenu: {
      marginTop: theme.spacing.apply(4),
      marginBottom: theme.spacing.apply(4),
    },
    meetings: {
      paddingTop: theme.spacing.apply(4),
      paddingBottom: theme.spacing.apply(4),
      paddingLeft: isMobile ? 0 : theme.spacing.apply(2),
    },
    meeting: {
      width: isMobile ? '100%' : '300px',
    },
    addNewBtn: {
      height: '100%',
      minHeight: '180px',
    },
  }))
  return useStyles()
}
