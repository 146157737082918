/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable no-undef */
// import React from 'react'

const privacyStatement = {
  'header.0': 'IMPRESSUM',
  'paragraph.0': ``,
  'header.1': 'Herausgeber',
  'paragraph.1': `Kreis Minden-Lübbecke
  - Die Landrätin -
  Portastraße 13
  32423 Minden
  Telefon: +49 571 807 0
  Fax: +49 571 807 30000
  E-Mail: info@minden-luebbecke.de

  Der Kreis Minden-Lübbecke ist eine Körperschaft öffentlichen Rechts und wird durch die Landrätin, Anna Katharina Bölling, vertreten.

  Als Aufsichtsbehörde ist die Regierungspräsidentin des Regierungsbezirks Detmold für den Kreis Minden-Lübbecke zuständig.
  `,
  'header.2': `Verantwortlich für den Internetauftritt`,
  'paragraph.2': `Dennis Vogt
  Telefon: +49 571 807 21723
  Fax: +49 571 807 31723
  E-Mail: dennis.vogt@minden-luebbecke.de
  Korinna Möller
  Telefon: +49 571 807 11020
  Fax: +49 571 807 31025
  E-Mail: k.moeller@minden-luebbecke.de
  `,
  'header.3': `Konten, Identifikationsnummern und Elektronische Rechnung`,
  'paragraph.3': `Konto der Kreiskasse
  Sparkasse Minden-Lübbecke (BLZ 490 501 01)
  Kontonummer: 400 020 16
  IBAN: DE63 4905 0101 0040 0020 16
  BIC: WELADED1MIN
  Gläubiger-ID: DE3500000000034938
  `,
  'header.4': `Umsatzsteuer-ID`,
  'paragraph.4': `Die Umsatzsteuer-Identifikationsnummer (§ 27a Umsatzsteuergesetz) lautet DE 126021237
  `,
  'header.5': `Empfang elektronischer Rechnungen`,
  'paragraph.5': `Leistungserbringer für öffentliche Auftraggeber haben das Recht, Ihre Forderungen als elektronische Rechnung zu übermitteln. Der Kreis Minden-Lübbecke hat die technischen Voraussetzungen dafür geschaffen, Unternehmen können ihre elektronischen Rechnungen auch im Format XRechnung einreichen.

    Die Leitweg-ID des Kreises Minden-Lübbecke lautet: 057700024024-34001-80

    Elektronische Rechnungen senden Sie bitte an folgende E-Mail-Adresse: rechnung@invoice.minden-luebbecke.de`,
  'header.6': `Rechtliche Hinweise`,
  'header.7': `Allgemeine Hinweise`,
  'paragraph.7': `Der Kreis Minden-Lübbecke übernimmt keinerlei Gewähr für die Aktualität, Richtigkeit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Kreis Minden-Lübbecke, die sich auf Schäden materieller oder ideeller Art beziehen, welche durch die Nutzung oder Nichtnutzung der dargebotenen Informationen beziehungsweise durch die Nutzung fehlerhafter oder unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Kreises Minden-Lübbecke kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Kreis Minden-Lübbecke behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
  `,
  'header.8': `Elektronische Kommunikation`,
  'paragraph.8': `Alle Organisationseinheiten und ein Großteil der Mitarbeiterinnen und Mitarbeiter des Kreises Minden-Lübbecke können per E-Mail erreicht werden. Eine Kommunikation per E-Mail kann aber Sicherheitslücken aufweisen. So können beispielsweise E-Mails auf ihrem Weg an Mitarbeiterinnen und Mitarbeiter der Kreisverwaltung von versierten Internet-Nutzenden aufgehalten und eingesehen oder verändert und dann in dieser Form weitergeleitet werden. Sollte der Kreis Minden-Lübbecke eine E-Mail von Ihnen erhalten, so wird davon ausgegangen, dass der Kreis Minden-Lübbecke zu einer Beantwortung per E-Mail an die von Ihnen übermittelte Absenderadresse berechtigt ist. Ansonsten müssen Sie auf eine andere Art der Kommunikation verweisen.

  Nach § 3a des Verwaltungsverfahrensgesetzes ist eine rechtsverbindliche elektronische Kommunikation im Verwaltungsverfahren möglich, soweit die empfangende Person oder Stelle hierfür einen Zugang eröffnet hat. Gemäß § 126a des Bürgerlichen Gesetzbuches gilt entsprechendes im Privatrecht. Weitere Informationen finden Sie in diesem Impressum im Abschnitt „rechtsverbindliche elektronische Kommunikation“.
  Sollten Sie E-Mails mit Dateianhängen an den Kreis Minden-Lübbecke versenden, so beachten Sie bitte, dass der Kreis Minden-Lübbecke nicht alle auf dem Markt gängigen Dateiformate und Anwendungen unterstützen kann.

  Folgende Dateiformate können bearbeitet werden:

  Portable Document Format (.pdf)
  Rich Text Format (.rtf)
  Textdateien (.txt, .csv)
  Microsoft Word ab Version 2007 (.docx)
  Microsoft Excel ab Version 2007 (.xlsx)
  Microsoft Powerpoint ab Version 2007 (.pptx)
  Joint Picture Export Group (.jpg oder .jpeg)
  Bilddateien im Tagged Image File Format (.tif)

  Weitere Formate sind nur mit ausdrücklicher Zustimmung des Kreises Minden-Lübbecke zulässig. Die Gesamtgröße der eingehenden E-Mails ist auf 50 MB und bei De-Mails auf 10 MB begrenzt. Größere Nachrichten werden automatisch abgelehnt. Wir antworten Ihnen nach Möglichkeit auf dem von Ihnen eingangs gewählten Weg. Antworten können Sie ausdrücklich in Papierform wünschen. Ist Ihre Nachricht nicht bearbeitbar, informieren wir Sie darüber.

  `,
  'header.9': `Rechtsverbindliche elektronische Kommunikation`,
  'paragraph.9': `Als moderne Dienstleistungsverwaltung möchte der Kreis Minden-Lübbecke es allen Bürgerinnen und Bürgern ermöglichen, auch rechtsverbindlich mit der Kreisverwaltung und ihren Dienstkräften auf elektronischem Weg in Kontakt zu treten. Eine Kommunikation per E-Mail ohne zusätzliche Sicherheitsmaßnahmen kann aber Sicherheitslücken aufweisen - beispielsweise können E-Mails auf ihrem Weg an unsere Mitarbeiterinnen und Mitarbeiter von versierten Internet-Nutzenden aufgehalten und eingesehen werden. Aus diesem Grund eröffnen wir interessierten Bürgerinnen und Bürgern sowie Firmen und Behörden den Zugang zu rechtsverbindlicher elektronischer Kommunikation auf zwei Wegen:

  elektronische Dokumente mit qualifizierter elektronischer Signatur über unsere virtuelle Poststelle (VPS)
  absenderbestätigte De-Mail

  `,
  'header.10': `Virtuelle Poststelle`,
  'paragraph.10': `Über die Voraussetzungen zur Nutzung der qualifizierten elektronischen Signatur (QES) informiert die Bundesnetzagentur. Der Kreis Minden-Lübbecke unterstützt derzeit nur akkreditierte Signatur- und Verschlüsselungsverfahren. Die Virtuelle Poststelle unterstützt das Verschlüsselungsverfahren S/MIME (Secure / Multipurpose Internet Mail Extensions). Für die Verschlüsselung benötigen Sie unseren öffentlichen Schlüssel, der Ihnen hier zum Download bereitsteht. Der Kreis Minden-Lübbecke verwaltet (speichert) Ihren öffentlichen Schlüssel in einem zentralen Zertifikatsspeicher. Sie erreichen unsere VPS über die Adresse info@vps.minden-luebbecke.de.

  `,
  'header.11': `De-Mail`,
  'paragraph.11': `De-Mail ermöglicht den verschlüsselten und authentifizierten Versand von elektronischen Nachrichten und Dateianhängen. Sie benötigen hierfür ein spezielles De-Mail-Postfach bei einem akkreditierten De-Mail-Diensteanbieter. Die De-Mail-Adresse des Kreises Minden-Lübbecke lautet info@minden-luebbecke.de-mail.de.

  Für eine absenderbestätigte De-Mail müssen Sie sich an Ihrem De-Mail-Konto mit hohem Authentisierungsniveau anmelden. Um die Anmeldung mit hohem Authentisierungsniveau der Empfängerin oder des Empfängers der Nachricht kenntlich zu machen, bestätigt Ihr De-Mail-Diensteanbieter dies. Hierzu versieht er im Auftrag der Senderin oder des Senders die Nachricht mit einer dauerhaft überprüfbaren qualifizierten elektronischen Signatur. Wenn Sie Ihrer De-Mail Dateien anhängen, bezieht sich die qualifizierte elektronische Signatur auch auf diese. Bitte beachten Sie, dass Sie für die absenderbestätigte De-Mail keine pseudonyme De-Mail-Adresse verwenden können.

  Häufig gestellte Fragen zu De-Mail beantwortet der Beauftragte der Bundesregierung für Informationstechnik.
  `,
  'header.12': `Weitere rechtliche Hinweise`,
  'paragraph.12': `Sofern auf Verweisziele ("Links") direkt oder indirekt verwiesen wird, die außerhalb des Verantwortungsbereiches des Kreises Minden-Lübbecke liegen, haftet dieser nur dann, wenn er von den Inhalten Kenntnis hat und es ihm technisch möglich oder zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Für darüber hinausgehende Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung der so dargebotenen Informationen entstehen, haftet allein der Anbieter dieser Seiten, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
  `,
  'header.13': `Urheber- und Bildrechte`,
  'paragraph.13': `Sofern auf Verweisziele ("Links") direkt oder indirekt verwiesen wird, die außerhalb des Verantwortungsbereiches des Kreises Minden-Lübbecke liegen, haftet dieser nur dann, wenn er von den Inhalten Kenntnis hat und es ihm technisch möglich oder zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Für darüber hinausgehende Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung der so dargebotenen Informationen entstehen, haftet allein der Anbieter dieser Seiten, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
  Der Kreis Minden-Lübbecke ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafik- und Tondateien und Texte zu beachten, von ihm selbst erstellte Grafiken, Sounds und Texte zu nutzen oder auf lizenzfreie Grafiken, Sounds und Texte zurückzugreifen. Sollte sich auf den jeweiligen Seiten dennoch eine ungekennzeichnete, aber durch fremdes Urheberrecht geschützte Grafik, ein Sound oder Text befinden, so konnte das Urheberrecht vom Kreis Minden-Lübbecke nicht festgestellt werden. Im Falle einer solchen unbeabsichtigten Urheberrechtsverletzung wird der Kreis Minden-Lübbecke das entsprechende Objekt nach Benachrichtigung aus seiner Publikation entfernen bzw. mit dem entsprechenden Urheberrecht kenntlich machen. Das Urheberrecht für veröffentlichte, vom Kreis Minden-Lübbecke selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Sounds oder Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Kreises Minden-Lübbecke nicht gestattet.

  Bildrechte für Bilder, die auf dem Portal www.fotolia.de erworben wurden, liegen bei den Urhebern und können der beigefügten Liste entnommen werden:

  Fotolia-Copyrightliste (PDF, 4,3 MB) (Hier müsste dann unsere Liste eingefügt werden)
  Fotolia Nachweis ab 12.04.2019
`,
  'header.14': `Rechtswirksamkeit des Haftungsausschlusses und Datenschutz`,
  'paragraph.14': `Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.

  Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet.
  Analysedienste

  Dieser Internetauftritt benutzt Matomo, eine freie Webanalyse-Software, die unter https://matomo.org/ im Rahmen der GPL (GNU General Public License) verfügbar ist.

  Matomo verwendet so genannte „Cookies” - Textdateien, die auf Ihrem Computer gespeichert werden, und die es uns erlauben, die Nutzung des Internetauftritts zu analysieren. Informationen, die die Cookies über Ihre Benutzung unseres Internetangebotes erzeugen, werden an einen eigenen Server unseres Hostingdienstleisters, dem Kommunalen Rechenzentrum Minden-Ravensberg/Lippe (krz), übertragen und ausschließlich hausintern ausgewertet. Diese Informationen können nicht mit Personen in Verbindung gebracht werden. Wir nutzen die gewonnenen Daten lediglich, um unsere Webpräsenz zu analysieren und zu verbessern. Weder nutzen wir Sie für Werbung, noch verkaufen wir diese Informationen weiter. Ihre IP-Adresse wird vor der Speicherung anonymisiert. Matomo ist nach den Empfehlungen des Unabhängigen Landeszentrums für Datenschutz (ULD) Schleswig-Holstein konfiguriert und folgt den geltenden Datenschutzbestimmungen.

  Haben Sie Ihren Browser so eingestellt, dass Ihr Besuch generell nicht von Statistik-Software erfasst werden soll, so respektiert Matomo dies und wird Ihren Besuch nicht erfassen. Verschiedenste Browser verwenden dazu einen sogenannten "Do Not Track Header".

  Sie haben das Tracking in Ihrem Browser deaktiviert.

  Ihr Besuch auf dieser Website wird nicht statistisch erfasst, da Ihr Browser uns mitgeteilt hat, dass Sie kein Tracking wünschen.

  Um das Tracking zu aktivieren, müssen Sie die sogenannte "Do Not Track"-Einstellung in Ihrem Browser deaktivieren.

`,
}

export default privacyStatement
