import React from 'react'
import GraphContainer from 'components/Graph'
import GraphLegend from 'components/GraphLegend'
import { Split } from '@geoffcox/react-splitter'
import EditorPanel from 'components/GraphEditorComponents/GraphEditor/EditorPanel'
import styles from './EditorStyles'


const Editor = () => {
  const classes = styles()
  return (
    <Split minPrimarySize="50%" minSecondarySize="25%" initialPrimarySize="75%">
      <div className={classes.graphContainer}>
        <div className={classes.legends}><GraphLegend /></div>
        <GraphContainer className={classes.graph} isEditor />
      </div>
      <div className={classes.sideNavContainer}>
        <EditorPanel />
      </div>
    </Split>

  )
}
export default Editor
