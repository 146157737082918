import { useJss } from 'lq-ui'

export default () => {
  const useStyles = useJss({
    checked: {},
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    control: {
      width: '100%',
      wordBreak: 'break-word',
    },
    direction: {
      flexDirection: 'row',
    },
    font: {
      fontSize: 'small',
    },
    label: {
      paddingLeft: '13px',
    },
    root: {
      color: '#085986 !important',
      '&$checked': {
        color: '#085986 !important',
      },
    },
    top: {
      marginTop: '10px',
    },
  })
  return useStyles()
}
