/* eslint-disable react/no-array-index-key */
import React from 'react'
import PropType from 'prop-types'
import parse from 'autosuggest-highlight/parse'
import match from 'autosuggest-highlight/match'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
// import { Grid } from '@material-ui/core'

const Option = ({
  option, state, onDelete, onEdit,
}) => {
  const useStyles = makeStyles({
    option: {
      width: '100%',
      wordBreak: 'break-word',
      display: 'flex',
      justifyContent: 'space-between',
    },
    checkedColor: {
      color: '#3A6311',
    },
    bold: {
      fontWeight: 700,
    },
    light: {
      fontWeight: 400,
    },
  })
  const classes = useStyles()
  const matches = match(option, state.inputValue)
  const parts = parse(option, matches)

  // const handleDelete = (e) => {
  //   e.stopPropagation()
  //   onDelete()
  // }

  return (
    <div className={classes.option}>
      <div>
        {parts.map((part, index) => (
          <span
            key={index}
            className={part.highlight ? classes.bold : classes.light}
          >
            {part.text.word || part.text}
          </span>

        ))}
      </div>
      { (!state.selected && !option.inputValue)
      && (
      <div>
        <EditIcon onClick={(e) => onEdit(e, option)} color="primary" />
        <DeleteIcon
          onClick={(e) => {
            e.stopPropagation()
            onDelete(option)
          }}
          color="error"
        />
      </div>
      )}

    </div>
  )
}

Option.propTypes = {
  option: PropType.oneOfType([PropType.string, PropType.object]).isRequired,
  // value: PropType.string.isRequired,
  onDelete: PropType.func.isRequired,
  onEdit: PropType.func.isRequired,
  state: PropType.object.isRequired,
}

export default Option
