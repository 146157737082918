import React from 'react'
import { hooks } from 'lq-ui'
import Layout from 'pages/layouts/General'
import SignInForm from 'pages/Signin/SignInForm'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styles from './styles'


export default () => {
  const classes = styles()
  const { i18n: { useMessage } } = hooks

  return (
    <Layout title={useMessage('providers.toptitle')}>
      <div className={classes.content}>
        <FontAwesomeIcon icon="sign-in-alt" size="4x" className={classes.icon} />
        <Typography variant="h4" color="primary">
          {useMessage('signin.label')}
        </Typography>
        <SignInForm />
      </div>
    </Layout>
  )
}
