import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select } from 'lq-ui'
import {
  Dialog, DialogActions, Button, Grid, DialogTitle, DialogContent,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { getAllConditions, getEdgeConditions } from 'services/graph'
import ConditionEditor from 'components/GraphEditorComponents/GraphEditor/ConditionEditor'
import ConfirmEdgeChanges from 'components/GraphEditorComponents/GraphEditor/ConfirmEdgeChanges'
import Conditions from './Coditions'
import { useGraphForm } from '../FormContex/useForm'

const sortByLabel = (a, b) => {
  if (a.label < b.label) return -1
  if (a.label > b.label) return 1
  return 0
}

const EdgeForm = React.memo(({
  node, destination, onClose, edgeId,
}) => {
  const { state: { edges, nodes }, actions } = useGraphForm()


  const conditions = getAllConditions(edges)
  const edge = edges.find((e) => e.id === edgeId) || {}

  const edgeConditions = React.useMemo(() => (edgeId
    ? getEdgeConditions(edge, conditions)
    : []), [conditions, edge, edgeId])
  const nodesList = nodes ? nodes.map((n) => ({ ...n, value: n.id })) : []
  const nodesOptions = node === 0
    ? nodesList.filter((n) => n.id !== 0)
    : destination === 'from'
      ? nodesList.filter((n) => n.id !== 0)
      : nodesList


  const [selectedConditions, setSelectedConditions] = React.useState(edgeConditions)
  const [open, setOpen] = React.useState(false)
  const [formState, setFormState] = useState({
    targetNode: destination === 'from' ? edge.to : edge.from,
    selectedEdge: edge,
    open: false,
  })

  const handleChange = (v) => {
    const defaultConditions = Object.keys(conditions).reduce((o, c) => ({ ...o, [c]: '' }), {})
    const newEdge = {
      ...defaultConditions,
      from: (destination === 'from' ? node : v),
      to: (destination === 'to' ? node : v),
      id: edgeId,
    }

    actions.addNewEdge(newEdge)

    setFormState({
      ...formState,
      selectedEdge: newEdge,
      targetNode: v,
    })
  }

  const conditionsOptions = Object.keys(conditions)
    .reduce((acc, i) => [...acc, { label: i, value: i }], [])

  const handleRemoveEdgeCondition = (condition) => {
    actions.removeEdgeCondition({
      condition,
      edge,
    })
    setSelectedConditions(selectedConditions.filter((c) => c !== condition))
  }

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth scroll="body">
      <DialogTitle>Änderung der Verbindung!</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ padding: '10px' }}>
          <Grid item xs={12}>
            <Select
              required
              inputLabel={destination === 'from' ? 'Nach' : 'Von'}
              value={formState.targetNode}
              items={nodesOptions.sort(sortByLabel).filter((n) => n.id !== -1)}
              onChange={(val) => handleChange(val)}
              textColor="#3A6311"
              disabled={!!edge.to}
            />
          </Grid>
          <Grid item xs={12}>
            <Conditions
              conditions={conditionsOptions}
              onChange={(val) => setSelectedConditions(val)}
              selectedConditions={selectedConditions}
              onDelete={handleRemoveEdgeCondition}
            />
          </Grid>
          <Grid item xs={12}>
            {selectedConditions.map((c) => <ConditionEditor condition={c} edge={edge} key={c} />)}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="secondary"
          variant="contained"
        >
          <CloseIcon />
          Schließen
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Speichern
        </Button>
      </DialogActions>
      {open && (
      <ConfirmEdgeChanges
        onClose={() => setOpen(false)}
        formClose={onClose}
        data={edge}
      />
      )}
    </Dialog>

  )
})

EdgeForm.propTypes = {
  edgeId: PropTypes.string.isRequired,
  node: PropTypes.number.isRequired,
  destination: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EdgeForm
