import React from 'react'
import PropTypes from 'prop-types'

import {
  Card, CardActions, CardContent, IconButton, Button, Grid,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography,
} from '@material-ui/core'
import { useAuth } from 'lq-ui/lib/auth/use-auth'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import {
  deleteMeeting, acceptProposal, rejectProposal, removeProposal,
} from 'store/actions'
import { useDispatch } from 'react-redux'
import styles from './styles'
import RenderMeetingViewElement from './RenderMeetingViewElement'

const MeetingView = ({ meeting, isProposal, onSelect }) => {
  const classes = styles()
  const { user } = useAuth()
  const [open, setOpen] = React.useState(false)
  const dispatch = useDispatch()

  const meetingContent = [
    'email', 'phone', 'serviceHours', 'contactName',
    'address', 'shortDescription', 'provider', 'district',
  ]

  const onDelete = () => dispatch(deleteMeeting(meeting.id)).then(() => setOpen(false))

  const proposalDelete = () => dispatch(removeProposal(meeting.id))
  const proposalAccept = () => dispatch(acceptProposal(meeting.id))
  const proposalReject = () => dispatch(rejectProposal(meeting.id))

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2" color="primary" display="inline">
          {meeting.name}
        </Typography>
        {(isProposal ? [...meetingContent, 'proposedBy'] : meetingContent)
          .map((e) => <RenderMeetingViewElement label={e} meeting={meeting} key={e} />)}
        {/* <div style={{ wordBreak: 'break-word', color: '#0f5886' }}>
          <b>{hooks.i18n.useMessage('card.serviceHours')}</b>
          <span>
            {meeting.serviceHours}
          </span>
        </div> */}
      </CardContent>
      <CardActions>
        {isProposal && user
          ? (
            <Grid container justify="space-between">
              <IconButton onClick={proposalAccept}>
                <CheckIcon color="primary" />
              </IconButton>
              <IconButton aria-label="share" onClick={proposalReject}>
                <ClearIcon />
              </IconButton>
              <IconButton aria-label="share" onClick={proposalDelete}>
                <DeleteIcon color="error" />
              </IconButton>
            </Grid>
          )
          : (
            <>
              <IconButton onClick={() => onSelect(meeting)}>
                <EditIcon color="primary" />
              </IconButton>
              {user
              && (
              <IconButton aria-label="share" onClick={() => setOpen(true)}>
                <DeleteIcon color="error" />
              </IconButton>
              )}
            </>
          )}
      </CardActions>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Sprachgelegenheit  löschen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Sie können jederzeit neue Sprachtreffs hinzufügen. Wollen Sie diesen Sprachtreff endgültig löschen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onDelete} color="error" variant="contained" className={classes.deleteBtn}>
            Löschen
          </Button>
          <Button onClick={() => setOpen(false)} color="primary" variant="contained">
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

MeetingView.propTypes = {
  meeting: PropTypes.objectOf(PropTypes.string).isRequired,
  isProposal: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default MeetingView
