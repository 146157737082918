import { makeStyles } from '@material-ui/core/styles'
import useWindowSize from 'hooks/useWindowSize'

const useStyles = () => {
  const { isMobile } = useWindowSize()

  const styles = makeStyles((theme) => ({
    root: {
      marginBottom: theme.spacing(isMobile ? 2 : 6),
    },
    top: {
      marginBottom: theme.spacing(6),
    },
    mainText: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2),
    },
    image: {
      width: isMobile ? '50px' : '120px',
      marginLeft: theme.spacing(isMobile ? 2 : 4),
      verticalAlign: 'middle',
    },
  }))
  return styles()
}
export default useStyles
