// import { useJss } from 'lq-ui'

// export default () => {
//   const useStyles = useJss({
//     container: {
//       width: '100%',
//       border: '1px solid #085986',
//       borderRadius: '4px',
//       alignItems: 'center',
//     },
//     infoIcon: {
//       padding: '5px',
//       fontSize: '2rem',
//       float: 'left',
//       verticalAlign: 'middle',
//       color: '#209BCD',
//     },
//   })
//   return useStyles()
// }


import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    borderColor: theme.palette.secondary.main,
    border: '3px solid',
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export default useStyles
