import api from 'api'


export const updateMeetingInformation = (meeting) => async (dispatch, getState) => {
  dispatch({ type: 'UPDATE_MEETING_INFORMATION', payload: meeting })
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()

  try {
    const updatedMeetings = meetings.map((m) => m.id).includes(meeting.id)
      ? [...meetings.filter((m) => m.id !== meeting.id), meeting]
      : [...meetings, meeting]

    const graph = await api.graph.set({
      nodes,
      edges,
      providers,
      meetings: updatedMeetings,
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const deleteMeeting = (meetingId) => async (dispatch, getState) => {
  const {
    graph: {
      nodes, edges, providers, meetings, files,
    },
  } = getState()
  dispatch({ type: 'DELETE_MEETING', payload: meetingId })
  try {
    const graph = await api.graph.set({
      nodes,
      edges,
      providers,
      meetings: meetings.filter((m) => m.id !== meetingId),
      files,
    })
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const loadProposals = () => async (dispatch) => {
  const proposals = await api.proposals.get()
  return dispatch({ type: 'PROPOSALS_LOAD_SUCCESS', payload: proposals })
}


export const proposeMeeting = (meeting) => async (dispatch) => {
  dispatch({ type: 'PROPOSE_MEETING', payload: meeting })
  try {
    const graph = await api.proposals.set(meeting)
    dispatch({ type: 'GRAPH_LOAD_SUCCESS', payload: graph })
    dispatch({
      type: 'OPEN_CONFIRMATION_MODAL',
      payload: { title: 'confirmation.proposal.sent.title', body: 'confirmation.proposal.sent.body', type: 'success' },
    })
  } catch (error) {
    dispatch({ type: 'GRAPH_LOAD_FAILURE', error })
  }
}

export const acceptProposal = (proposalId) => async (dispatch, getState) => {
  const { proposals } = getState()
  const response = await api.proposals.accept(proposalId)
  const isValidEmail = await response.json()

  dispatch({ type: 'ACCEPT_PROPOSAL', payload: proposals.filter((p) => p.id !== proposalId) })

  const titleMessage = isValidEmail
    ? 'confirmation.proposal.action.proposedByEmail.valid.title'
    : 'confirmation.proposal.action.proposedByEmail.inValid.title'

  const bodyMessage = isValidEmail
    ? 'confirmation.proposal.action.proposedByEmail.valid.body'
    : 'confirmation.proposal.action.proposedByEmail.inValid.body'

  dispatch({
    type: 'OPEN_CONFIRMATION_MODAL',
    payload: { title: titleMessage, body: bodyMessage },
  })
}

export const rejectProposal = (proposalId) => async (dispatch, getState) => {
  const { proposals } = getState()
  const response = await api.proposals.reject(proposalId)
  const isValidEmail = await response.json()

  dispatch({ type: 'REJECT_PROPOSAL', payload: proposals.filter((p) => p.id !== proposalId) })

  const titleMessage = isValidEmail
    ? 'confirmation.proposal.action.proposedByEmail.valid.title'
    : 'confirmation.proposal.action.proposedByEmail.inValid.title'

  const bodyMessage = isValidEmail
    ? 'confirmation.proposal.action.proposedByEmail.valid.body'
    : 'confirmation.proposal.action.proposedByEmail.inValid.body'

  dispatch({
    type: 'OPEN_CONFIRMATION_MODAL',
    payload: { title: titleMessage, body: bodyMessage },
  })
}

export const removeProposal = (proposalId) => async (dispatch, getState) => {
  const { proposals } = getState()
  const response = await api.proposals.remove(proposalId)
  const isValidEmail = await response.json()

  dispatch({ type: 'REMOVE_PROPOSAL', payload: proposals.filter((p) => p.id !== proposalId) })

  const titleMessage = isValidEmail
    ? 'confirmation.proposal.action.proposedByEmail.valid.title'
    : 'confirmation.proposal.action.proposedByEmail.inValid.title'

  const bodyMessage = isValidEmail
    ? 'confirmation.proposal.action.proposedByEmail.valid.body'
    : 'confirmation.proposal.action.proposedByEmail.inValid.body'

  dispatch({
    type: 'OPEN_CONFIRMATION_MODAL',
    payload: { title: titleMessage, body: bodyMessage },
  })
}
