import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getConditionValues } from 'store/selectors'
import RenderSelectCondition from 'components/GraphView/ConditionComponent/RenderSelectCondition'

const ConditionComponent = ({ condition, isCriteria }) => {
  const { conditions, persona } = useSelector((state) => state.appState)
  const conditionValues = useSelector(getConditionValues(condition))
  if (conditionValues) {
    const selectOptions = conditionValues.type === 'bool'
      ? conditionValues.items.map((i) => ({ label: i, value: (i === 'Ja') }))
      : conditionValues.items.map((i) => ({ label: i, value: i }))
    return (
      <RenderSelectCondition
        condition={condition}
        conditions={isCriteria ? persona : conditions}
        selectOptions={selectOptions}
        isCriteria={isCriteria}
      />
    )
  }
  return null
}

ConditionComponent.defaultProps = {
  isCriteria: false,
  condition: null,
}

ConditionComponent.propTypes = {
  condition: PropTypes.string,
  isCriteria: PropTypes.bool,
}

export default ConditionComponent
