import React from 'react'
import api from 'api'

export default (adress, map) => {
  const [currentAddress, setCurrentAddress] = React.useState(adress)
  // this is the address which is shown on the map
  const [currentMap, setCurrentMap] = React.useState(map)

  const updateMap = (newAddress) => {
    if (currentAddress !== newAddress) {
      api.maps.preview(newAddress)
        .then((mapSource) => {
          setCurrentMap(mapSource)
          setCurrentAddress(newAddress)
        })
    }
  }
  const handleAddressLeave = (value) => updateMap(value)

  return {
    value: adress,
    map: currentMap,
    adress: currentAddress,
    currentValue: currentAddress,
    onBlur: handleAddressLeave,
  }
}
