import React from 'react'
import {
  Dialog, DialogContent, DialogContentText, DialogActions, Button,
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { resetAppStatus } from 'store/actions'


const ErrorDialog = () => {
  const dispatch = useDispatch()
  const onConfirm = () => dispatch(resetAppStatus())


  return (
    <Dialog open>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ups -da ist etwas schief gelaufen. Bitte probieren Sie es erneut.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
        >
          Abbrechen
        </Button>
      </DialogActions>

    </Dialog>

  )
}


export default ErrorDialog
