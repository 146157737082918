import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog, DialogActions, Button, TextField, DialogTitle, DialogContent, DialogContentText,
} from '@material-ui/core'
import { useGraphForm } from 'components/GraphEditorComponents/GraphEditor/FormContex/useForm'
import ConditionValueSelect from '../ConditionValueSelect'

const StringConditionEditor = ({
  conditionLabel, value, handleChangeCondition, selectItems,
}) => {
  // const [val, setVal] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [confirmEdit, setConfirmEdit] = React.useState(false)
  const [confirmDelete, setConfirmDelete] = React.useState(false)

  const [editValue, setEditValue] = React.useState('')

  const { actions: { removeConditionValue, editConditionValue } } = useGraphForm()

  const handleEdit = () => {
    editConditionValue({ condition: conditionLabel, oldValue: open, newValue: editValue })
    setConfirmEdit(false)
    setEditValue('')
    setOpen(false)
  }


  const handleDelete = () => {
    removeConditionValue({ condition: conditionLabel, value: confirmDelete })
    setConfirmDelete(false)
  }

  return (
    <>
      <ConditionValueSelect
        label={conditionLabel}
        options={selectItems?.map((x) => x.label) || []}
        value={value}
        onChange={(v) => {
          handleChangeCondition(v)
        }}
        onEdit={(e, option) => {
          e.stopPropagation()
          setEditValue(option)
          setOpen(option)
        }}
        onDelete={(o) => {
          setConfirmDelete(o)
        }}
      />

      <Dialog open={!!open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth scroll="body">
        <DialogTitle>Änderung der Werte!</DialogTitle>
        <DialogContent>
          <TextField
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            label={conditionLabel}
            fullWidth
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary" variant="contained">
            Abbrechen
          </Button>
          <Button
            onClick={() => setConfirmEdit(true)}
            color="primary"
            variant="contained"
            disabled={!editValue || editValue === open}
          >
            Bestätigen
          </Button>
        </DialogActions>
      </Dialog>


      { confirmEdit
        && (
        <Dialog open>
          <DialogTitle>Änderung der Werte!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sind Sie sicher, dass Sie diesen Wert verändern möchten?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmEdit(false)} color="secondary" variant="contained">
              Abbrechen
            </Button>
            <Button onClick={handleEdit} color="primary" variant="contained">
              Bestätigen
            </Button>
          </DialogActions>

        </Dialog>
        )}


      { confirmDelete
        && (
        <Dialog open>
          <DialogTitle>Löschen der Werte!</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Sind Sie sicher, dass Sie diesen Wert löschen möchten?
              Sie können jederzeit später einen neuen Wert hinzufügen!
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDelete(false)} color="secondary" variant="contained">
              Abbrechen
            </Button>
            <Button onClick={handleDelete} color="primary" variant="contained">
              Bestätigen
            </Button>
          </DialogActions>

        </Dialog>
        )}
    </>
  )
}

StringConditionEditor.defaultProps = {
  conditionLabel: null,
  handleChangeCondition: null,
  value: null,
  selectItems: [],
}

StringConditionEditor.propTypes = {
  conditionLabel: PropTypes.string,
  handleChangeCondition: PropTypes.func,
  value: PropTypes.string,
  selectItems: PropTypes.arrayOf(PropTypes.any),
}

export default StringConditionEditor
