import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, CardHeader, CardMedia, CardActions, Typography, Grid, CardContent, Collapse, Link,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import { useAuth } from 'lq-ui/lib/auth/use-auth'

import styles from './ProviderCardStyles'


const ProviderCard = ({
  provider, onClick, onDelete, print,
}) => {
  const classes = styles()
  const [expanded, setExpanded] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const { user } = useAuth()


  return (
    <Grid item xs={12} className={classes.root}>
      <Card>
        <CardHeader title={provider.name} />
        {provider.shortDescription
          && (
          <CardContent className={classes.shortDescription}>
            <Typography variant="body2" color="textSecondary" component="p">
              {provider.shortDescription}
            </Typography>
          </CardContent>
          )}
        <CardMedia
          className={classes.media}
          image={provider.map}
          title="Paella dish"
        />
        <CardContent>
          {provider.email
          && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Email:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              <Link href={`mailto:${provider.email}`}>{provider.email}</Link>
            </Typography>
          </Grid>
          )}
          {provider.phone && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Telefon:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              <Link href={`tel:${provider.phone}`}>{provider.phone}</Link>
            </Typography>
          </Grid>
          )}
          {provider.url && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Website:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              {provider.url}
            </Typography>
          </Grid>
          )}
          {provider.address && (
          <Grid container justify="space-between">
            <Typography variant="subtitle2" component="b">
              Address:
            </Typography>
            <Typography variant="body1" component="p" className={classes.labels}>
              {provider.address}
            </Typography>
          </Grid>
          )}
        </CardContent>
        {!print
        && (
        <CardActions>
          <Grid container justify="space-between">
            <Grid item>
              {user
                && (
                <>
                  <IconButton onClick={() => onClick(provider)}>
                    <EditIcon color="primary" />
                  </IconButton>
                  <IconButton aria-label="share" onClick={() => onDelete(provider)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </>
                )}
            </Grid>
            {provider.description
              && (
              <Grid item>
                <IconButton
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                >
                  <ExpandMoreIcon />
                </IconButton>
              </Grid>
              )}
          </Grid>

        </CardActions>
        )}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {provider.description}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </Grid>
  )
}

ProviderCard.defaultProps = {
  onClick: () => null,
  onDelete: () => null,
  print: false,
}

ProviderCard.propTypes = {
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    map: PropTypes.string,
    url: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  print: PropTypes.bool,
}

// #TODO Enable Provider Editor and Delete

export default ProviderCard
