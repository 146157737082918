/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

const Tags = ({ values, getTagProps }) => {
  const useStyles = makeStyles({
    root: {
      height: 'auto',
      padding: '3px',
    },
    label: {
      whiteSpace: 'normal',
      wordBreak: 'break-word',
    },
  })
  const classes = useStyles()
  return values.map((option, index) => (
    <Chip
      label={option}
      classes={{ root: classes.root, label: classes.label }}
      {...getTagProps({ index })}
    />
  ))
}

export default Tags
