
import { makeStyles } from '@material-ui/core'
import useWindowSize from 'hooks/useWindowSize'

export default () => {
  const { isMobile } = useWindowSize()
  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: theme.spacing(4),
    },
    document: {
      minWidth: isMobile ? '100%' : '300px',
    },
    addNewBtn: {
      height: '100%',
      minHeight: '180px',
      minWidth: '100%',
    },
  }))
  return useStyles()
}
