
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: '50%',
    margin: '10% auto 0',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  icon: {
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
    borderRadius: '50%',
    background: theme.palette.primary.main,
  },
}))

export default useStyles
