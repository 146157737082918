import React from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import Bubble from '../Bubble'


const BubbleGrid = ({ bubbleList }) => (
  <Grid container alignItems="center" justify="space-evenly" spacing={2}>
    {bubbleList.map((bubble) => (
      <Grid item key={bubble.label}>
        <Bubble bubble={bubble} />
      </Grid>
    ))}
  </Grid>
)

BubbleGrid.propTypes = {
  bubbleList: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default BubbleGrid
