const HOST = process.env.REACT_APP_BACKEND_HOST || '/api'


const upload = async (url = '', data = {}) => {
  const auth = window.localStorage.getItem('access-token')

  const response = await fetch(HOST + url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      ...(auth ? { Authorization: `Bearer ${auth}` } : {}),
    },

    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: data,
  })
  return response.json()
}

export {
  upload,
}
