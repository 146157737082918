import React from 'react'
import { useSelector } from 'react-redux'
import EdgesList from 'components/GraphEditorComponents/GraphEditor/EdgesList'
import { nodeSelector } from 'store/selectors'
import NodeExclusionCriteria from 'components/GraphEditorComponents/GraphEditor/NodeExclusionCriteria'
import styles from './styles'


const NodeEdges = () => {
  const classes = styles()
  const selectedNode = useSelector(nodeSelector)

  return (
    <div className={classes.container}>
      {selectedNode !== null
        && (
          <>
            <EdgesList destination="to" node={selectedNode} />
            <EdgesList destination="from" node={selectedNode} />
            <NodeExclusionCriteria />
          </>
        )}
    </div>
  )
}

export default NodeEdges
