import React from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogActions,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { Select } from 'components/FormFields'
import { useSelector, useDispatch } from 'react-redux'
import { sortByLabel } from 'helpers/sort'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { getAllConditions } from 'services/graph'
import { updateNodeInformation } from 'store/actions'
import { guidGenerator } from 'helpers/guidGenerator'
import InputField from './InputField'
// import nodeTextFields from './NodeFormTextFields'

const NodeForm = ({ node = {}, onClose, isNewNode }) => {
  const nodeSchema = yup.object().shape({
    name: yup.string().required('required field'),
    label: yup.string().required('required field'),
    group: yup.string().required('required field'),
    from: isNewNode && yup.number().required('required field'),
  })

  const methods = useForm({
    defaultValues: node,
    resolver: yupResolver(nodeSchema),
  })

  const { watch, control, handleSubmit } = methods

  const dispatch = useDispatch()
  const { nodes, edges } = useSelector((state) => state.graph)
  const groupOptions = [{ label: 'gate', value: 'gate' }, { label: 'course', value: 'course' }]
  const nodesOptions = nodes
    ? nodes.map((n) => ({ label: n.name, value: n.id })).sort(sortByLabel).sort(sortByLabel)
    : []

  const conditions = getAllConditions(edges)
  const defaultConditions = Object.keys(conditions).reduce((o, c) => ({ ...o, [c]: '' }), {})

  const onSubmit = (data) => {
    if (isNewNode) {
      const id = Math.max.apply(null, nodes.map((n) => n.id)) + 1
      const predecessorEdge = {
        ...defaultConditions,
        from: data.from,
        to: id,
        id: guidGenerator(),
      }
      const successorEdge = data.to && {
        ...defaultConditions,
        from: id,
        to: data.to,
        id: guidGenerator(),
      }
      const NodeEdges = data.to ? [predecessorEdge, successorEdge] : [predecessorEdge]
      dispatch(updateNodeInformation({ ...data, id }, NodeEdges))
      onClose()
    } else {
      dispatch(updateNodeInformation(data))
      onClose()
    }
  }

  const { group } = watch()

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {node.label || 'Element Hinzufügen'}
      </DialogTitle>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit((data) => onSubmit(data))}>
          <DialogContent>
            <Grid container>
              <InputField defaultValue={node.name} field={{ name: 'name', label: 'Name', type: 'text' }} />
              <InputField defaultValue={node.label} field={{ name: 'label', label: 'Label', type: 'text' }} />
              {isNewNode
              && (
                <>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="from"
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          options={nodesOptions}
                          label="Von"
                          name={name}
                          error={error}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name="to"
                      render={({
                        field: { onChange, value, name },
                        fieldState: { error },
                      }) => (
                        <Select
                          value={value}
                          onChange={onChange}
                          options={nodesOptions}
                          label="Nach"
                          name={name}
                          error={error}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {node.id !== 0
              && (
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="group"
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      options={groupOptions}
                      label="Group"
                      name={name}
                      error={error}
                    />
                  )}
                />
              </Grid>
              )}

              <InputField
                defaultValue={node.shortDescription}
                field={
                {
                  name: 'shortDescription', label: 'Kurzbeschreibung', type: 'text', rows: 4,
                }
              }
              />

              <InputField
                defaultValue={node.description}
                field={
                {
                  name: 'description', label: 'Detailbeschreibung', type: 'text', rows: 8,
                }
              }
              />
              { (node.group === 'course' || group === 'course')
              && (
              <>
                <InputField
                  defaultValue={node.duration}
                  field={{
                    name: 'duration', label: 'Dauer Zeitraum', type: 'text', group: 'course',
                  }}
                />
                <InputField
                  defaultValue={node.hours}
                  field={
                  {
                    name: 'hours', label: 'Kurs Stunden', type: 'text', group: 'course',
                  }
              }
                />
              </>
              )}

              {/* {nodeTextFields
                .filter((field) => !field.group || field.group === node.group || group === 'course')
                .map((f) => (
                  <Grid item key={f.name} xs={12}>
                    <InputField defaultValue={node[f.name]} field={f} />
                  </Grid>
                ))} */}
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={onClose}
              color="secondary"
              variant="contained"
            >
              Abbrechen
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
            >
              Speichern
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    </Dialog>
  )
}


NodeForm.defaultProps = {
  isNewNode: false,
}

NodeForm.propTypes = {
  node: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    name: PropTypes.string,
    shortDescription: PropTypes.string,
    description: PropTypes.string,
    hours: PropTypes.string,
    duration: PropTypes.string,
    group: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  isNewNode: PropTypes.bool,
}

export default NodeForm
