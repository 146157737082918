import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'

const conditionDetails = (c) => {
  const label = Object.keys(c)[0]
  const value = Object.values(c)[0]
  return { label, value }
}

const formatValue = (v) => {
  if (typeof v === 'string') {
    return v
  }
  return v ? 'Ja' : 'Nein'
}

const SetConditions = ({ edgeConditions }) => edgeConditions.length > 0 && (
<div style={{ paddingTop: '.5rem' }}>
  {edgeConditions.map((c) => (
    <div key={conditionDetails(c).label}>
      <Typography component="h6" variant="subtitle2" display="inline">
        {`${conditionDetails(c).label}: `}
      </Typography>
      <Typography component="h6" variant="subtitle2" display="inline">
        {formatValue(conditionDetails(c).value)}
      </Typography>
    </div>
  ))}
</div>
)

SetConditions.propTypes = {
  edgeConditions: PropTypes.arrayOf(PropTypes.any).isRequired,
  // edge: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default SetConditions
